.referredUsersContainer{
    display: inline-flex;
    padding: 0.625rem 1.25rem;
    justify-content: space-between;
    align-items: center;
    background: var(--neutral-white, #FFF);
    width: 100%;
}

.payoutDataContainer{
    display: inline-flex;
    padding: 0.625rem 1.25rem;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #E7E7E7;
    background: var(--neutral-white, #FFF);
    width: 100%;
}

.referralContainer{
    display: inline-flex;
    padding: 0.625rem 1.25rem;
    justify-content: space-between;
    align-items: center;
    background: var(--neutral-white, #FFF);
    width: 100%;
}

.historyAndPayoutContainer{
    margin: 10vw 0;
    width: 100%;
}

.test{
    height: 10%;
}

.layoutBalance{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
