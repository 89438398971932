@import '../../marketplace.css';

.root {
	padding: 20px 0;
}

.title {
	margin: 0 0 30px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	color: #4a4a4a;
}

.trendItem:hover {
	text-decoration: none;
} 

.trendBox {
	border-radius: 4px;
	height: 155px;
	padding: 10px 16px;

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	display: flex;

	align-items: flex-end;

	transition: var(--transitionStyleButton);

	&:hover {
		transform: scale(1.02);
		box-shadow: var(--boxShadowSectionLocationHover);
	}
}

.trendLabel {
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	color: white;
	text-transform: uppercase;
}