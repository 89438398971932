.containerSocialButtonActions{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 2.5px;
    @media (--viewportSmall) {
        margin-top: 10px;
      }
    
}


.buttonSocialButtonIconLike{
    width: 50px;
    padding: 0px;
    height: fit-content;
    border: hidden;
    text-align: center;

}

.buttonSocialButtonIconLike p{
    margin: 0px;
    padding:  0px ;
    font-size: 10px;
    color: #979797;
    text-align: center;
    line-height: 14px;
    font-size: 500;
}

.socialButtonIconLike{
    width: 29px;
    height: 29px;
  @media (--viewportMedium) {
    width: 27px;
    height: 27px;
    
  }
}