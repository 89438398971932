@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.card {
  @apply --marketplaceInputStyles;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: var(--attentionColor);

  @media (--viewportMedium) {
    height: 38px;
  }
  @media (--viewportLarge) {
    height: 38px;
    padding: 6px 0 14px 0;
  }
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage  {
  margin-bottom: 30px;
  color: var(--failColor);
  font-size: medium;
  display: block;
  width: 100%;
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }


}

.errorMessageHidden{
  visibility: hidden;

  margin-bottom: 30px;
  color: var(--failColor);
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.paymentHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 0;
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 6px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.subTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 7px;
  margin-top: 10px;
  overflow-x: auto;
  word-break: break-all;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 5px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: 5%;

  margin-bottom: 30%;

  @media (--viewportMedium) {
    margin-top: 1%;

    margin-bottom: 15%
  }

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.paymentInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.addressForm {

  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 25;
  }
}
.heading {
  margin-top: 29px;
  margin-bottom: 0;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-top: 27px;
    margin-bottom: 5px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 5px;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 7px;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.addressLines{
  margin: 0;
  padding: 0 24px;
  margin-bottom: 15px;
}

.addressLines p{
  margin: 0;
}


.missingPhoneError {
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  color: #9F6000;
  background-color: #FEEFB3;
  
}
.missingPhoneErrorButton {
  font-size: medium;
  text-align: center;
  color:red;
}

.hidden{
  visibility: hidden;
}


.checkbox_container {
  display: flex;
  margin-bottom: 12px;
  align-items: center; /* Aligns checkbox and label vertically */
}

.checkbox_container input[type="checkbox"] {
  margin-right: 5px; /* Adjust space between checkbox and label */
}

.isFacturationAddressNeededLabel,
.isFacturationAddressNeededCheckbox{
  width: fit-content;
}

.MonthlyInstallmentsComponent{
  margin-top: 1%;
}

.isFacturationAddressNeededCheckbox{
  display: none;
}
.checkbox label:before {
  border-radius: 3px;
}