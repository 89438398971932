@import '../../marketplace.css';

.root {
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--matterColorNegative);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noImageIcon {
  padding-top: 24px;
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.noImageText {
  @apply --marketplaceH6FontStyles;
  color: var(--matterColor);
  padding-bottom: 24px;

  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.canvas{
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}


.hover{
  display: none;
  @media (--viewportSmall) {
    display:block;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
  }
}

.image{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 4px 4px 0 0;
  margin-top: 50%;
  transform: translatey(-50%);
}

.imageHover{
  width: 50%;
  opacity: 0.75;
}

.parent{
  position: absolute;
  inset: 0px;
}

.parent:hover .canvas{
  opacity: 0.3;
}

.parent:hover .hover{
  opacity:1;
}