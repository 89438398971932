@import '../../marketplace.css';

.input {
  border-bottom-color: var(--attentionColor);
}

.priceDiscountText{
  font-family: Arial, sans-serif;
  font-size: 0.8rem;
  text-align: left;
  line-height: 1.5;
  color: #212529;
  padding: 0;
  font-weight: normal;
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.custom{
  position: absolute;
  margin-left: -5px;
  margin-top: 15px;
}
