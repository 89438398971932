@import '../../../marketplace.css';

.root {
	margin-bottom: -10px;
	display: flex;
	flex-wrap: wrap;
}

.filterItem {
	background-color: #f3b4c5;
	border-radius: 55px;
	padding: 9px 12px;
	margin-right: 10px;
	margin-bottom: 10px;

	font-size: 14px;
	color: white;
}

.closeIcon {
	stroke: white;
	margin-left: 7px;
    width: 9px;
    height: 10px;
    &:hover {
    	cursor: pointer;
    }

}

@media (max-width: 768px) {
  .sortContainer {
    display: none;
  }
}
