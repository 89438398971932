@import '../../marketplace.css';

.root {
  @apply --marketplaceButtonStyles;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.primaryButtonRoot {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.secondaryButtonRoot {
  @apply --marketplaceButtonStylesSecondary;

  /* We must lift up the text from the center since it looks better with
   the button border. Also clears the top padding set for link
   elements looking like buttons */
  padding: 0 0 2px 0;
}

.referralButtonRoot{
  background-image: url("../../assets/referral.jpg") ;
  background-size:cover;
  width: 100%;
  height: 0.5%;
  max-width:100%;

  border: solid 1px #E9E9E9;
	position: relative;
	background-position: center center;
}

.shoppingCartButtonRoot{
  background-image: url("../../assets/shoppingCart.jpg") ;
  background-size:cover;
  width: 100%;
  height: 0.5%;
  max-width:100%;

  border: solid 1px #E9E9E9;
	position: relative;
	background-position: center center;
}

.referralButtonRoot:hover {
  /*background-color: var(--marketplaceColor); /* Green */
}
.shoppingCartButtonRoot:hover {
  /*background-color: var(--marketplaceColor); /* Green */
  /*color: white;*/
}

.inlineTextButtonRoot {
  @apply --marketplaceLinkStyles;
}

.primaryButton {
  /* Class handle for primary button state styles */
}
.secondaryButton {
  /* Class handle for secondary button state styles */
}

.referralButton{
 /* Class handle for secondary button state styles */
}
.shoppingCartButton{
  /* Class handle for secondary button state styles */
 }
.inProgress {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.inProgress:disabled {
  background-color: var(--successColor);
}

.secondaryButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.referralButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.shoppingCartButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.ready {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.ready:disabled {
  background-color: var(--successColor);
}

.secondaryButton.ready:disabled {
  background-color: var(--matterColorLight);
}

.referralButton.ready:disabled {
  background-color: var(--matterColorLight);
}
.shoppingCartButton.ready:disabled {
  background-color: var(--matterColorLight);
}
.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
  display: inline;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.secondaryButton .spinner {
  stroke: var(--matterColorAnti);
}

.secondaryButton .checkmark {
  stroke: var(--matterColorAnti);
}

.referralButton .spinner {
  stroke: var(--matterColorAnti);
}

.referralButton .checkmark {
  stroke: var(--matterColorAnti);
}

.shoppingCartButton .spinner {
  stroke: var(--matterColorAnti);
}

.shoppingCartButton .checkmark {
  stroke: var(--matterColorAnti);
}