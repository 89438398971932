@import '../../marketplace.css';

.root {
  @apply --marketplaceModalRootStyles;
  background-color: #f0eff0;

}

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.submitEmailContent,
.genericErrorContent,
.emailSubmittedContent,
.emailNotVerifiedContent {
  @apply --marketplaceModalBaseStyles;
  @media (--viewportMedium) {
    flex-basis: 510px;
    margin-top: 32px;
    padding: 37px 42px 38px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  font-size: 27px;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}
