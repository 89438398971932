@import '../../marketplace.css';

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 48px auto;
  }
}

.heading {
  text-align: center;
  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.title {
  margin: 0 0 24px;
}

.subtitle {
  @apply --marketplaceH2FontStyles;

  font-weight: normal;
  
  margin-top: 0;
  text-align: center;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    padding: 0 15%;
  }
}

.contentWrapper {
}

.contentSection {
  margin-bottom: 22px;
}

.contentTitle {
  @apply --marketplaceH2FontStyles;

  
  font-weight: bold;
  color: var(--marketplaceColor);
  
  margin-top: 0;
  text-align: center;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.featuredLine {
  color: var(--marketplaceColor);
}

.sectionImage {
  width: 100%;
}

.contentSection h4 {
  font-size: 22px;
  font-weight: bold;
  color: black;
}

.contentSection p {
  font-size: 18px;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 12px; 
}
