@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 24px 0;
    text-decoration: inherit;

    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {
      border-bottom: 4px solid var(--marketplaceColor);
      border-radius: 0;
      text-decoration: none;
    }
  }
}

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 32px;

  /* fill */
  background-color: var(--matterColorLight);

}

.wrapper {
  /* shadows */
  box-shadow: var(--boxShadowLight);
}

/* logo */
.logoLink {
  flex-shrink: 0;

  max-width: 20%;
  flex-basis: 20%;

  padding: 11px 8px 11px 0;
  height: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportLarge) {
    padding: 11px 8px;
    padding-left: 0px;
  }
  &:hover {
    /*background-color: var(--matterColorBright);*/
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 35px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;

  padding-left: 8px;
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearch {
  height: var(--topbarHeightDesktop);
}

.blogLink {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.blogIcon {
  width: 20px;
  height: 18px;
  margin-bottom: 2px;
}

.blog {
  color: inherit;
  font-size: 15px;
  line-height: 16px;
}

.favoritesLink {
  height: 121%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #979797;
  padding: 0px 10px;
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.favoritesMessage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  color: #979797;
  margin: auto;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.favoritesIcon {
  width: 20px;
  height: 20px;
}

.favorites {
  color: inherit;
  font-size: 15px;
  line-height: 16px;
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --marketplaceH4FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListingIcon {
  height: 20px !important;
  width: 20px !important;
  margin-right: 15px;
}

.createListing {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

/* Inbox */
.inboxLink {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  color: #979797;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.inboxIcon {
  height: 20px !important;
}

.inbox {
  color: inherit;
  font-size: 15px;
  line-height: 16px;
}

.inboxIconGroup {
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 8px;
  height: 8px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 4px;
  right: 5px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

.messageNotificationDot {
  /* Dimensions */
  width: 8px;
  height: 8px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 4px;
  left: 15px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}
.ordersNotificationDot {
  /* Dimensions */
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;

  /* Position */
  position: absolute;
  top: 0;
  right: 0;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatarWrapper {
  position: relative;
  margin: 16px 0;
}

.avatar {
  width: 36px;
  height: 36px;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.signup,
.login {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH4FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPageMain {
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.navContainer {
  width: 100%;
  position: relative;
  display: inline-block;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;

  /* fill */
  background-color: var(--matterColorLight);


}

.quickLinks {
  display: flex;
}

.navBtn, .navBtn:visited {
  font-size: 15px;
  color: var(--matterColor);
  text-decoration: none;
  margin-right: 23px;
}

.navBtn:hover, .navBtn:active {
  color: var(--marketplaceColor);
  text-decoration: none;
  cursor: pointer;
}

.dropbtn {
  padding: 15px 0;
  font-size: 15px;
  border: none;
  color: black;
}

.dropbtn:hover {
  color: var(--marketplaceColor);
  text-decoration: none;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdownContent a, .dropdownContent a:visited {
  margin-bottom: 5px;
  color: black;
}

.dropdownContent a:hover {
  color: #ff7f66;
  font-weight: bold;
  text-decoration: none;
}

.navBtn:hover .dropdownContent {
  display: block;
  padding: 20px 15px;
}

.navDropdown {
  color: black;
  display: block;
}

.messageNotificationBadge{
    /* Font */
    @apply --marketplaceH6FontStyles;
    color: var(--matterColorLight);
    text-align: center;
  
    background-color: var(--failColor);
  
    /* Layout of a red dot */
    display: inline-block;
    width: auto;
    min-width: 14px;
    height: 14px;
    border-radius: 12px;
    padding: 0;
    margin-left: 4px;

    font-size: 8px;
  
    /* Font margins off */
    margin-top: 0;
    margin-bottom: 0;
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
  
      top: 0px;
      left: 15px;
      position: absolute;
    }
}


.yourListingTitle{
  position: relative;
  display: block;
  font-weight: 600;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;
  font-size:20px !important;
  /* Layout details */
  text-align: left;
  transition: var(--transitionStyleButton);
  color: #4A4A4A;
  @media (--viewportMedium) {
    margin: 0;
  }
}


.separator{
  height: 1px;
  margin: 0px;
  background: #e0e0e0;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
}@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 24px 0;
    text-decoration: inherit;

    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {
      border-bottom: 4px solid var(--marketplaceColor);
      border-radius: 0;
      text-decoration: none;
    }
  }
}

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: fit-content;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 32px;

  /* fill */
  background-color: var(--matterColorLight);

}

.wrapper {
  /* shadows */
  box-shadow: var(--boxShadowLight);
}

/* logo */
.logoLink {
  flex-shrink: 0;

  max-width: 20%;
  flex-basis: 20%;

  padding: 11px 8px 11px 0;
  height: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportLarge) {
    padding: 11px 8px;
    padding-left: 0px;
  }
  &:hover {
    /*background-color: var(--matterColorBright);*/
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 35px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;


  border: 1px solid #EEEEEE;
  background-color: #F5F5F5;
  padding-left: 8px;

  border-radius: 10px;

  margin: auto
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearch {
  height: var(--topbarHeightDesktop);
  background: #F5F5F5;
}

.blogLink {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.blogIcon {
  width: 20px;
  height: 18px;
  margin-bottom: 2px;
}

.blog {
  color: inherit;
  font-size: 15px;
  line-height: 16px;
}

.favoritesLink {
  height: 121%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #979797;
  padding: 0px 10px;
  margin: auto;
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.favoritesMessage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  color: #979797;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.favoritesIcon {
  width: 20px;
  height: 20px;
}

.favorites {
  color: inherit;
  font-size: 15px;
  line-height: 16px;
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --marketplaceH4FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListingIcon {
  height: 20px !important;
  width: 20px !important;
  margin-right: 15px;
}

.createListing {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

/* Inbox */
.inboxLink {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  color: #979797;
  margin: auto;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.inboxIcon {
  height: 20px !important;
}

.inbox {
  color: inherit;
  font-size: 15px;
  line-height: 16px;
}

.inboxIconGroup {
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 8px;
  height: 8px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 4px;
  right: 5px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

.messageNotificationDot {
  /* Dimensions */
  width: 8px;
  height: 8px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 4px;
  left: 15px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}
.ordersNotificationDot {
  /* Dimensions */
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;

  /* Position */
  position: absolute;
  top: 0;
  right: 0;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatarWrapper {
  position: relative;
  margin: 16px 0;
}

.avatar {
  width: 36px;
  height: 36px;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.signup,
.login {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH4FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 15px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.currentPageMain {
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.navContainer {
  width: 100%;
  position: relative;
  display: inline-block;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;

  /* fill */
  background-color: var(--matterColorLight);


}

.quickLinks {
  display: flex;
}

.navBtn, .navBtn:visited {
  font-size: 15px;
  color: var(--matterColor);
  text-decoration: none;
  margin-right: 23px;
}

.navBtn:hover, .navBtn:active {
  color: var(--marketplaceColor);
  text-decoration: none;
  cursor: pointer;
}

.dropbtn {
  padding: 15px 0;
  font-size: 15px;
  border: none;
  color: black;
}

.dropbtn:hover {
  color: var(--marketplaceColor);
  text-decoration: none;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdownContent a, .dropdownContent a:visited {
  margin-bottom: 5px;
  color: black;
}

.dropdownContent a:hover {
  color: #ff7f66;
  font-weight: bold;
  text-decoration: none;
}

.navBtn:hover .dropdownContent {
  display: block;
  padding: 20px 15px;
}

.navDropdown {
  color: black;
  display: block;
}

.messageNotificationBadge{
    /* Font */
    @apply --marketplaceH6FontStyles;
    color: var(--matterColorLight);
    text-align: center;
  
    background-color: var(--failColor);
  
    /* Layout of a red dot */
    display: inline-block;
    width: auto;
    min-width: 14px;
    height: 14px;
    border-radius: 12px;
    padding: 0;
    margin-left: 4px;

    font-size: 8px;
  
    /* Font margins off */
    margin-top: 0;
    margin-bottom: 0;
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
  
      top: 0px;
      left: 15px;
      position: absolute;
    }
}


.yourListingTitle{
  position: relative;
  display: block;
  font-weight: 600;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;
  font-size:20px !important;
  /* Layout details */
  text-align: left;
  transition: var(--transitionStyleButton);
  color: #4A4A4A;
  @media (--viewportMedium) {
    margin: 0;
  }
}


.separator{
  height: 1px;
  margin: 0px;
  background: #e0e0e0;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
}