@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;
}

/* Dummy Topbar */

.topbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  align-items: center;
  justify-content: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    height: var(--topbarHeightDesktop);
    justify-content: center;
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--CheckoutPage_logoHeight)) / 2) 24px;

  @media (--viewportLarge) {
    padding: calc((var(--topbarHeightDesktop) - var(--CheckoutPage_logoHeightDesktop)) / 2) 36px;
  }
}

.logoMobile {
  display: block;

  & path {
    fill: var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: block;
  }
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 10%;
  }

  @media (--viewportXLarge) {
    padding: 0 20%;
  }
}

.bookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    max-width: calc(60% - 15px);
    margin-top: 40px;
    margin-right: 15px;
  }
}

.aspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  @media (--viewportLarge) {
    display: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  display: block;

  @media (--viewportLarge) {
    margin-left: 48px;
  }
}

.avatarMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-top: 10px;
    margin-bottom: 18px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 24px;
    padding: 0;
  }
}

.containerHeading{
  margin-top: 10px;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    margin-top: 10px;
    margin-bottom: 18px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 24px;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 7px;
  overflow-x: auto;
  word-break: break-all;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.subTitle{

    /* Font */
    @apply --marketplaceH2FontStyles;
    color: var(--matterColor);
  
    /* Layout */
    width: 100%;
    margin-top: 0;
    margin-bottom: 7px;
    overflow-x: auto;
    word-break: break-all;
  
    @media (--viewportMedium) {
      font-size: 30px;
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 5px;
    }

}

.listingTitleMobile {
  width: 100%;
  @apply --marketplaceH3FontStyles;

  border-bottom: 1px solid #fd7c67;

  width: fit-content;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 38px;
  }
  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 14px;
  }
}

.paymentContainer {

  margin-bottom: 20vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  @media (--viewportSmall) {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-top: 2px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    padding: 0;
  }
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;

  @media (--viewportMedium) {
    margin: 10px 0 12px 0;
  }
}
.orderError {
  color: var(--failColor);
}
.notFoundError {
  color: var(--attentionColor);
}

.speculateError {
  color: var(--failColor);
  margin: 19px 0 1px 0;

  @media (--viewportLarge) {
    margin: 28px 48px 0 48px;
  }
}

.paymentForm {
  flex-grow: 1;
  margin-bottom: 10vw;
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
  display: none;

  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: calc(40% - 15px);
    padding: 10px;

    /* Position */
    margin: 40px 0 24px 15px;

    /* Coloring */
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
    position: fixed;
    right: calc(10% - 25px);
    top: 40px;
    position: -webkit-sticky;
    position: sticky;
  }
}

.detailsContainerMobile {
  bottom: 0%;
  position: fixed;
  right: 0px;
  left: 0px;
  z-index: 100;

  @media (--viewportLarge) {
    display: none;
  }
}

.detailsAspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.detailsHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px;
  }
}

.detailsTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 17px;
    margin-bottom: 9px;
  }
}

.detailsSubtitle {
  @apply --marketplaceH5FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  /* Layout spacing */
  margin: 5px 24px 25px 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
  }
}

.bookingBreakdown {
  @media (--viewportLarge) {
    margin: 0 48px 10px;
  }
}

.paymentMethodSelector{
  display: flex;
  flex-direction: column;
width: 100%;
justify-content: space-evenly;
text-align: center;
float: none;
margin-bottom: 3%;
}

.paymentMethodSelectorContainer{
  display: flex;
  margin-bottom: calc(1% + 1px);
}

.paypalButton{
  width: 100%;
}

.paymentSelectorButton{
  width: 100%;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: 1px solid #B7B7B7;
  background: rgba(217, 217, 217, 0.00);
  height: 50px; /* Set a specific height */
  display: flex;
  align-items: center;
  justify-content: start; /* Align items to the start (left) */
  padding-left: 5%; /* Padding 5% from the left */

  margin: 10px 0 ;
}



.hidden{
  display: none;
}

.selected{
  /* background-color: var(--marketplaceColor); */
  border-bottom: 5px solid rgba(253, 124, 103, .7);
  border-right: 5px solid rgba(253, 124, 103, .7);

}

.submitButton{
  position: relative;
  margin-bottom: 0px;

  @media (--viewportLarge) {
    position: relative;

  }
}

.addressButton{
  margin-top: 10px;
}

.lineDiv{
  @apply --marketplaceListingAttributeFontStyles;
}

.buttonDisabled{
  pointer-events: none;

}

.divDisabled{
  cursor: not-allowed !important;
}

.tooltip {
  position: fixed;
  display: none;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  z-index: 999;
}

.address_line,
.name,
.neighborhood,
.state{
  display: flex;
  height: 3rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid #B7B7B7;
}

.ext_number,
.int_number,
.zip,
.city,
.phoneNumber{
  display: flex;
  height: 3rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid #B7B7B7;
}


.addressRowContainer{
  display: flex;
}

.addressIcon{
  float: left;
  margin: 0;
  -ms-transform: translateY(30%);
  transform: translateY(30%);
}

.addressContainerFlex{
  display: flex;
  justify-content: space-between;
}

.addressLineFlex{
  display: flex;
  justify-content: space-between;
}

.inputRoot{
  width: 100%;
  margin-right: 0;
  align-self: center;
}

.addressState{
  display: flex;
  height: 3rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid #B7B7B7;
}

.borderLine{
  border: 1px solid #B7B7B7;
  padding: 10px;
}

.borderLine:hover {
  cursor: pointer;
}

.IconPaypalPurchase{
  width: auto; /* or a specific width if needed */
  padding: 0; /* Adjust if needed */
  /* Ensure no excessive margin that might affect the button's height */
}


.error{
  color: var(--failColor);
}

.phoneNumberSavedContainer{
  border: 1px solid darkgray;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}