@import '../../marketplace.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.sendMessageContainer{
  display: flex;
  width: 100%;
}

.textarea {
  @apply --marketplaceH4FontStyles;
  border-bottom-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* Avoid text going behind the submit button */
  padding: 0;

  margin: 0;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: 100%;

  border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);
  background-color: transparent;

  max-height: 6vh !important;

  @media (--viewportMedium) {
    margin: 0;
    overflow-y: scroll;
    resize: none;
  }
  
  @media (--viewportLarge) {
    white-space: normal;
    text-overflow: wrapper;
    width: calc(100% - 52px);
    padding: 5vh 10px 5vh 15px;
    border: 1px solid;
    margin: 0;
    width: 100%;
    overflow-y: scroll;
    resize: none;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  display: flex;
  flex-direction: row;
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 0 0 0;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  border-radius: 100%;

  float: right;
  padding: 0 16px;
  min-height: auto;

  display: inline-block;
  align-self: center;

  height: 50px;
  width: 50px;
}

.sendIcon {
  margin: 0px 0px 0px -4px;
}

.sendIconPhotos{
}

.editListingLink {
  text-align: left;
  text-decoration: underline;
}

.editListingButton {
  padding: 0 16px;
  display: inline-block;
  align-self: center;
  justify-content: center;
}

.editListingDiv{
  align-items:flex-start;
}