@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 70px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
  background-color: #ffffff;
}

.label{
    @apply --marketplacePrincipalLabel;
    text-align: center;
    font-family: 'CircularStd', sans-serif !important;
    font-weight: bold;
  }
.label1{
    text-align: center;
    font-size:medium;
    color: var(--marketplaceColor);
  }
.title{
  @apply --marketplacePrincipalLabel;
  text-align: center;
  font-size:xx-large;
  color: var(--marketplaceColor);
}

  .bottomWrapper {
    @apply --marketplaceModalBottomWrapper;
    @media (--viewportMedium) {
      margin-top: 70px;
    }
  }
  
  .bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;
    line-height: 1.2;
  }
  
  .termsText {
    @apply --marketplaceModalHelperText;
  }

  .wrapper{
    display: flex;
  }

  .contryCode{
    width: 30%;
    float: left;
    @media (max-width: 768px) {
      font-size:0.5%;
      width: 40%;
      float: left;
    }
  }

  .filler{
    width: 5%;
  }

  .phone{
    width: 50%;
    float: right;
    @media (max-width: 768px) {
      width: 35%;
    }
  }
  .error{
    color: red; /* Set the text color to red */
    font-size: 14px; /* Set the font size */
    font-weight: bold; /* Set the font weight to bold for emphasis */
    margin-top: 5px; /* Add some space between the element above and the error text */
    text-align: center;
  }