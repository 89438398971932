@import '../../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  position: relative;
}

.filtersTitle {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 4px;
}

.filters {
  & > *:not(:last-child) {
    margin-bottom: 8px;
    height: auto;
  }
}
