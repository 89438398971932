@import '../../marketplace.css';

.root {
}

.title {
	margin: 0 0 12px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	color: #4a4a4a;
}

.followingContainer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	@media (--viewportMedium) {
		justify-content: flex-start;
	}
}

.followingItem {
	padding: 20px;
	text-align: center;
	width: 33.3%;
	@media (--viewportMedium) {
		width: 12.5%;
	}
}

.avatar {
	width: 64px;
	height: 64px;
	box-shadow: var(--boxShadow);
	margin: 0 auto;
}

.userLabel {
	font-size: 12px;
	margin: 5px 0 0;
	color: #812039;
}

.loaderContainer {
	margin-top: 10px;
	display: flex;
	justify-content: center;
}

.loader {
	color: var(--marketplaceColor);
}

