@import '../../marketplace.css';
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@500;600;700&display=swap');

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 4px;
}

.noUnderline:hover {
  text-decoration: none;
}

.imageWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  margin: auto;
  padding-bottom: 100%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px 4px 0 0;
}

.pictureSold{
  position: absolute ;
  width: 100%;
  height: 100%;
  z-index:1;
  background-color: var(--matterColor);
  opacity: 0.4;
}

.pictureSoldText{
  position:absolute ;
  top:45%;
  width: 100%;
  height: 100%;
  z-index:2; 
  /*color: #02E0B5;*/
  color: #FF7F66;
  font-family: 'Prompt', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
}
.pictureSoldTextHome{
  position:absolute ;
  top:45%;
  width: 100%;
  height: 100%;
  z-index:2; 
  /*color: #02E0B5;*/
  color: #FF7F66;
  font-family: 'Prompt', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 10px;
}

.pictureNotAvailableText{
  position:absolute ;
  top:45%;
  width: 100%;
  height: 100%;
  z-index:2; 
  color: #e6e6e6;
  font-family: 'Prompt', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
}
.pictureNotAvailableTextHome{
  position:absolute ;
  top:45%;
  width: 100%;
  height: 100%;
  z-index:2; 
  color: #e6e6e6;
  font-family: 'Prompt', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 10px;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.info {
  /* Layout */
  padding: 18px 8px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  align-items: stretch;

  @media (--viewportMedium) {
    padding: 24px 17px 19px;
  }
}

.price {
  /* Layout */
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-shrink: 0;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    margin-bottom: 18px;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  color: var(--marketplaceColor);
  font-size: 18px;
  line-height: 18px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.originalPriceValue {
  color: #d2d2d2;
  font-size: 14px;
  line-height: 18px;
  text-decoration: line-through;
  margin-left: 14px;

  @media (--viewportMedium) {
    color: var(--matterColor);
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  font-size: 14px;
  font-weight: normal;
  line-height: 18px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 4px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px
  }
}

.size {
  color: var(--matterColor);
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    margin-bottom: 15px
  }
}

.allCaps {
  text-transform: uppercase;
}

.authorInfo {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    margin-bottom: 21px
  }
}

.avatar {
  width: 27px;
  height: 27px;
  margin-right: 6px;
  & span {
    font-size: 12px;
  }
}

.separator {
  background-color: #d2d2d2;
  border-style: solid;
  border-color: #d2d2d2;
  margin-left: 0;
  margin-right: 0;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.actions {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.favorite{ 
  border-radius: 4px;
  border: solid 2px transparent;
  padding: 3px;
  & .favoriteIcon {
    margin-right: 8px;
    color: #979797;  
  }

  &:hover {
    border-color: var(--marketplaceColor);
    cursor: pointer;
  }

  &.favoriteLiked {
    color: var(--marketplaceColor);
    & .favoriteIcon {
      color: var(--marketplaceColor);
    }
  }
}



.favoriteLoader {
  color: #979797; 
}

.commentIcon {
  margin-right: 8px;
  color: #979797;
}


.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
