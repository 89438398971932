@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarMobileQuicklinks_topMargin: 50px;
}

.root {
  margin-top: var(--TopbarMobileQuicklinks_topMargin);
}

.linkParent {
  font-size: 22px;
  color: var(--matterColor) !important;
  font-weight: bold;
}

.nested {
  padding-left: 30px !important;
  font-size: 22px; 
}
