@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;
  --TopbarSearchForm_mobilePredictionTop: calc(
    var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
      var(--TopbarSearchForm_bottomBorder)
  );
}

.mobileInputRoot {
  width: 100%;
  border: 1px solid #000000;
  background: #F5F5F5;
  border-radius: 4px;
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}

.mobileIcon {
  margin-left: 10px;
  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 17px;
  align-self: stretch;
  background-color: #F5F5F5
}

.mobileInput {
  margin: 0;
  padding: 0;
  padding-left: 1vw;
  margin-left: 1vw;
  margin-right: 1vw;

  background-color: #F5F5F5;

  flex-grow: 1;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;
  height: 40px;

  border: none;

  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 3px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: #F5F5F5;
}

.desktopInput {
  flex-grow: 1;
  /* Font */
  @apply --marketplaceH4FontStyles;
  height: var(--topbarHeightDesktop);
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  font: icon;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
    color: #757575;
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(100vh - var(--TopbarSearchForm_mobilePredictionTop));
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;
}
