@import '../../marketplace.css';

:root {  }

.root {
	padding: 38px 24px;

	@media (--viewportMedium) {
		padding: 60px 160px 130px;
	}
}

.title {
	display: inline-flex;
	align-items: center;
	font-size: 28px;
	margin: 0 0 38px;
	line-height: 1;
	font-weight: normal;

	@media (--viewportMedium) {
		margin: 0 0 48px;
	}
}

.titleIcon {
	margin-left: 6px;
}

.loader {
	color: var(--marketplaceColor);
}

.notificationItem {
  background-color: white;
  color: black;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  font-size: 16px !important;
  margin-bottom: 25px !important;
  padding: 30px 35px !important;

}

.notificationItem:hover {
  cursor: pointer;
}

.notificationItem > div {
  display: flex;
  align-items: center;
}

.notificationAvatar {
  height: 90px;
  min-width: 96px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position:relative;
}

.notificationItemThumbnail {
  margin-right: 6px;
}

.notificationItem a:hover {
  color: #fd7c67;
}

.notificationTime {
  font-size: 12px;
}

.icon {
  color: inherit;
  font-size: 15px;
  line-height: 16px;
}
.thumbnailIcon {
  color: white;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  right: 13px;
  bottom: 5px;
  padding: 3px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
