@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ListingPage_desktopPadding: 150px;
  --ListingPage_carouselMargin: 8px;
  --ListingPage_thumbnailHeight: calc(118px - var(--ListingPage_carouselMargin));
}

/** GENERAL STYLES **/

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.desktopOnly {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.mobileOnly {
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}

/** MESSAGES **/

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

/** ACTION BAR **/

.actionBar {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 26px);
  margin: 20px auto;
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.AvatarInbox {
  display: flex;
  margin-bottom: 16px;
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }
  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

/** GRID LAYOUT **/

.contentContainer {
  margin: 50px 24px;
  margin-top: 20px;

  @media (--viewportMedium) {
    padding: 0 32px;
    display: flex;
    position: relative;
  }
}

.left {
  @media (--viewportMedium) {
    width: calc(50% - 35px);
    margin-right: 35px;
  }
}

.right {
  @media (--viewportMedium) {
    width: calc(50% - 35px);
    margin-left: 35px;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.desktopLeftPadding {
  @media (--viewportMedium) {
    padding-left: 20%;
  }
}

/** SECTION IMAGES **/

.sectionImages {
  margin-bottom: 0px;
}

img.imageMobile {
  object-fit: contain;
}

.imageWithZoom > div {
  background-position: center center;
}

.mobileCarouselWrapper {
  display: block;
  width: 100%;
  position: relative;
  @media (--viewportMedium) {
    display: none;
  }
}

.mobileCarouselSlider {
  width: 100%;
}

.desktopCarouselWrapper {
  display: none;
  @media (--viewportMedium) {
    display: flex;
  }
}
.containerSectionAvatarDesktop{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.carouselNavigator {
  flex: 0 0 20%;
}

.carouselSlider {
  flex: 0 0 80%;
}

.carouselDot {
  margin-right: var(--ListingPage_carouselMargin);
  margin-bottom: var(--ListingPage_carouselMargin);

  padding: 0;
  display: block;
  transition: opacity 0.3s;
  border: 0;

  & > img {
    opacity: 0.3;
    transition: opacity 0.3s;
  }

  &:global(.carousel__dot--selected) > img {
    opacity: 1;
  }
}

.carouselBackButton,
.carouselNextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  border-radius: 50%;
  padding: 2px;
  background-color: #fcfbfb;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  &:focus {
    outline: none;
  }
}

.carouselBackButton {
  left: -10px;
}

.carouselNextButton {
  right: -10px;
}

/** SOCIAL BUTTONS **/

.socialButtons {
  display: flex;
  justify-content: center;

  @media (--viewportMedium) {
    margin-bottom: 20px;
    justify-content: start;
  }
}

.socialButton {
  @apply --marketplaceButtonFontStyles;
  font-size: 14px;
  line-height: 1;

  height: 42px;

  margin-right: 2vw;
  margin-bottom: 11px;

  /* Dimensions */
  display: flex;
  align-items: center;
  padding: 8px;

  /* Borders */
  border: 1px solid #e5e1e6;
  border-radius: 3px;

  /* Colors */
  color: #979797;

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.2), 1px 1px 0 0 gray, 1px 1px 0 0 gray;

  &:focus {
    outline: none;
    background-color: #e5e1e6;
  }

  &:hover {
    outline: none;
    background-color: #e5e1e6;
    text-decoration: none;
  }
  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (--viewportSmall) {
    margin-right: 11px;
    padding: 10px 13px;
  }
}

.socialButtonSpinner {
  width: 16px;
  height: 16px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.socialButtonIconLike {
  width: 29px;
  height: 29px;
  @media (--viewportMedium) {
    width: 27px;
  height: 27px;
    display: flex;
    justify-content: center;
    flex-direction: column;   
    
  }

}

.socialButtonIconLike path{
  fill: #979797;
}

.socialButtonIconLikeActive path{
  fill: #FD7C67;
}

.socialButtonIconLike:hover path{
  fill: #FD7C67;
}



.buttonSocialButtonIconLike{
  width: auto;
  padding: 0px;
  height: fit-content;
  border: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  @media (--viewportSmall) {
    display: flex;
    flex-direction:  row-reverse;
    gap: 0.5rem;
    align-items: center;
    margin-top: 0px;
  }                                                                                                                                                    ;
}

.buttonSocialButtonIconLike span{
  font-size: 12px;
  color: #979797;

}

.socialButtonIcon {
  margin-right: 3px;
  width: 20px;
  height: 20px;

  @media (--viewportSmall) {
  }
}

.chatButtonIcon {
  margin-right: 0px;
  width: 1.15rem;
  height: 1.15rem;
  stroke: var(--marketplaceColor);
  color: var(--marketplaceColor);

  @media (--viewportSmall) {
    margin-right: 5px;
  }
}

.likeButtonActive {
  border-color: var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  color: white;
  &:focus {
    outline: none;
    background-color: var(--marketplaceColor);
  }
  &:hover {
    color: #979797;
    border-color: #e5e1e6;
  }
}

.likeButtonProfileActive {
  border: none;
  background-color: var(--marketplaceColor);
  min-width: 36px;
  border-radius: 8px;
  color: white;

  &:focus {
    outline: none;
    background-color: var(--marketplaceColor);
  }
  &:hover {
    color: #979797;
    border-color: #e5e1e6;
    cursor: pointer;
  }
}

.favoritesIcon {
  color: var(--marketplaceColor);
}
.favoritesLike {
  color: white;
}

.likeButton {
  border: 1px solid var(--marketplaceColor);
  min-width: 36px;
  border-radius: 8px;
  background-color: white;
  &:focus {
    outline: none;
    background-color: var(--marketplaceColor);
  }
  &:hover {
    color: #979797;
    border-color: #e5e1e6;
    cursor: pointer;
  }
}

.messageIcon {
  color: var(--marketplaceColor);
}

.messageButton {
  border: 1px solid var(--marketplaceColor);
  min-width: 36px;
  background-color: white;
  border-radius: 8px;
  &:hover {
    color: #979797;
    border-color: #e5e1e6;
    cursor: pointer;
  }
}

/** LIKES SECTION **/

.likesSection {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    margin: 0.5rem 0px;
  }
}

.likesAvatars {
  display: flex;
  margin-right: 12px;
}

.likesAvatarItem {
  width: 26px;
  height: 26px;
  border: 1px solid white;
  box-shadow: var(--boxShadow);

  & > span {
    font-size: 12px;
    padding-bottom: 0;
  }

  &:not(:first-child) {
    margin-left: -8px;
  }
}

.likesNameList {
  font-size: 14px;
  color: #979797;

  & > a {
    color: #812039;
  }
}

/** COMMENTS SECTION **/

.sectionComments {
  margin-top: 15px;
}

.titleSectionsComments{
font-weight: 500;
font-size: 18px;
margin-top: 0px;
margin-bottom: 13px;
}
.addComment {
  display: flex;
  padding-bottom: 15px;
  padding-top: 15px;
}

.addCommentAvatar {
  width: 35px;
  height: 35px;
  margin-right: 11px;
}

.commentForm {
  flex: 1 1 auto;
  text-align: right;
}
.commentFormTextarea {
  border: 1px solid #e5e1e6;
  border-radius: 3px;
  padding: 12px 14px;
  box-sizing: border-box;
  margin-bottom: 6px;
  font-size: 16px;

  transition: border-color var(--transitionStyle);

  &:hover,
  &:focus {
    border-color: var(--matterColor);
    outline: none;
  }
}

.commentFormTextareaDense {
  border: 1px solid #e5e1e6;
  border-radius: 3px;
  padding: 2px 4px;
  box-sizing: border-box;
  margin-bottom: 6px;
  font-size: 14px;
  min-height: 35px;
  transition: border-color var(--transitionStyle);

  &:hover,
  &:focus {
    border-color: var(--matterColor);
    outline: none;
  }
}

.commentFormSubmit {
  @apply --marketplaceButtonFontStyles;
  font-size: 14px;
  line-height: 1;

  /* Dimensions */
  padding: 10px 13px;

  /* Borders */
  border: 1px solid #e5e1e6;
  border-radius: 3px;

  /* Colors */
  color: #979797;

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: #e5e1e6;
    text-decoration: none;
  }
}

.commentErrorMessage {
  margin: 0 0 12px auto;
  color: var(--failColor);
  font-size: 14px;
  line-height: 1.1;
  max-width: 300px;
}
.comment {
  display: flex;
  padding: 0px 0;
  margin-top: 12px;
}

.comment__reply {
  display: flex;
  padding: 0px 0 0px 24px;
  background-color: #f8f8f8;
}

.comment__reply__box {
  padding: 0px 6px 0px 24px;
  background-color: #f8f8f8;
}

.comment__avatar {
  box-shadow: var(--boxShadow);
  width: 26px;
  min-width: 26px;
  height: 26px;
  border: 1px solid white;
  margin-right: 11px;

  & > span {
    font-size: 12px;
    padding: 0;
  }

  & > img {
    width: auto;
  }
}

.comment__content {
  flex: 1 1 auto;
}

.comment__message {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 4px;
}

.comment__author {
  margin-right: 11px;
  color: #812039;
}

.comment__date {
  font-size: 12px;
  line-height: 1;
  color: #979797;
}

.comment__reply__button {
  color: var(--marketplaceColor);
  font-size: 12px;
  line-height: 1;
  font-weight: bolder;
  display: inline-block;
  padding: 0;
  margin: 0 5px;
  border: 0;
  cursor: pointer;
  background-color: transparent;
}
.comment__reply__button:hover {
  color: var(--marketplaceColor);
}

/** PAYMENT ACTIONS **/

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 95px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.containerTitleHeading{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sectionActions {
  display: flex;
  position: fixed;
  padding-bottom: 20px;
  padding-top: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 2;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1); /* Ajusta según tus necesidades */

  background: white;
  bottom: 0px;
  left: 0px;
  width: 100%;
  gap: 0.5rem;
  @media (--viewportSmall) {
    position: relative;
    width: auto;
    display: flex;
    box-shadow: none;
    padding: 0px;
    gap: 0.5rem;
    margin-bottom: 5px;
    position: relative;
    width: 600px;
    justify-content: space-between;
    background: transparent;
  }
}

.msiMessage{
  margin-bottom: 25px;
  color : #2162A1;
  font-size: 16px !important;
}

.buyButton {
  max-width: 192px;

  @media (--viewportSmall) {
    max-width: 230px;
  }
}

.bargainButton {
  max-width: 192px;
  background-color: white;
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  &:hover {
    color: white;
  }
  @media (--viewportSmall) {
    max-width: 230px;
  }
}

.chatButton {
  max-width: 100px !important;
  background-color: white;
  border: 1px solid var(--marketplaceColor);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--marketplaceColor);

  border-color: #25D366;
  color: #25D366;
  gap: 5px;
  &:hover {
    color: white;

    border-color: #25D366;
    background-color: white;
    color: #25D366;
  }

  @media (max-width: 767px) {
    max-width: 2.8rem !important;
  }
}

.textButtonChat {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.discountButton {
  max-width: 192px;
  background-color: black;
  border: 1px solid black;
  color: white;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  

  &:hover {
    color: black;
    background-color: white;
  }
}

/** SECTION AVATAR **/

.sectionAvatar {
  display: flex;
  align-items: center;
}

.avatarDesktop {
  width: 39px;
  height: 39px;
  margin-right: 11px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.32);
}

.avatarAuthor {
  font-size: 14px;
  line-height: 16px;
  color: #812039;
  margin: 0;
}

.avatarLastUpdated {
  font-size: 12px;
  line-height: 14px;
  color: #979797;
  margin: 0;
}

/** SECTION HEADING (TITLE AND STUFF) **/

.sectionHeading {
  margin-top: 8px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: #101820;
  line-height: 40px !important;

  font-size: 32px;
  line-height: 44px;
  letter-spacing: unset;
  font-weight: normal;

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.prices {
  display: flex;
  align-items: baseline;
}

.productPrice {
  font-size: 27px;
  color: #101820;
  margin-right: 19px;
}

.originalPrice {
  font-size: 18px;
  color: #4a4a4a;
  text-decoration: line-through;
}

.shippingPrice {
  font-style: italic;
  font-size: 16px;
  color: #333333;
}

/** SECTION DESCRIPTION **/

.sectionDescription {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: #4a4a4a;
  font-size: 17px;

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/** SECTION FEATURES **/

.sectionFeatures {
  margin-bottom: 16px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 30px;
  }
}

.feature {
  display: flex;
  align-items: baseline;
}
.featureLabel {
  font-size:16px;
  color: var(--matterColor);

  @media (--viewportSmall) {
    font-size: 16px;
  }

}

.containerConditionText{
  display: flex;
  flex-direction: column;
}

.conditionTextMore{
  margin: 0;
  color: #979797;
  font-size: 16px;
  line-height: 12px;
  &:hover{
    color: #FD7C67;
    cursor: pointer;
  }
}


.descriptionText{
  font-size: 16px;
  font-weight: lighter !important;
  line-height: 16px;
  margin: 0;
  color:#212529;
  margin-bottom: 10px;
  margin-top: 10px;
}
.descriptionText span{
  color: #979797;
  font-size: 16px !important;
}


.featureBrand{
  padding: 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px !important;
}

.featureSize{
  font-size: 20px;
  font-weight: 400;
  color: #4A4A4A;
}
.featureSizeLabel{
  font-size: 20px;
  font-weight: 400;
  color: #4A4A4A;
  margin-right: 8px;

}

.featureValue {
  display: inline-block;
  border-radius: 4px;
  color: #812039;
  padding: 0px;
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 18px;

  @media (--viewportSmall) {
    font-size: 16px;
  }
}

/** SECTION MAP **/

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

/** SECTION REVIEW **/

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

/** SECTION HOST **/

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}


.enquirySubmitButtonWrapperChat {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}
/** PAYMENT IMAGES **/

.paymentImages {
  display: flex;
  margin-bottom: 62px;
}

.paymentImagesItem {
  height: 35px;
  margin-right: 12px;
}

/** EXTRAS? **/

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.hidden {
  display: none;
}

svg.svg {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 2px;
  text-transform: capitalize;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
}

button:hover,
.button:hover {
  border-color: #cdd;
}

.share-button,
.copy-link {
  padding-left: 30px;
  padding-right: 30px;
  border-color: #4a4a4a;
}

.copy-link:hover {
  border-color: #4a4a4a;
  cursor: pointer;
}

.share-button,
.share-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.share-dialog {
  width: 95%;
  max-width: 500px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
}

.share-dialog.is-open {
  display: block;
  z-index: 2;
}

header.shareHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.targets {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.close-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.close-button:hover {
  cursor: pointer;
}

.close-button:active,
.close-button:focus {
  outline: none;
}

.close-button svg {
  margin-right: 0;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
}

.pen-url {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sold {
  background-color: #f5f2ee;
  width: 50%;
  color: #af2f1d;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}

.setAsSold {
  width: 50%;
  margin-top: 20px;
}

.icons {
  display: flex;
  margin-left: 15px;
}

.iconsHidden {
  visibility: hidden;
}

.requestBookOfferMessage {
  font-size: 12px;
  color: gray;
  margin-left: 5px;
  line-height: 20px;
}

.lowerPriceMessage {
  font-size: 14px;
  color: black;
  margin-left: 5px;
  line-height: 20px;

  @media (--viewportMedium) {
    margin-top: 5px;
  }
}

.lowerPriceFieldsContainer {
  display: flex;
  margin-top: 30px;
  margin-bottom: 0px;
  justify-content: space-evenly;
}

.lowerPriceProviderCalc {
  width: 45%;
  justify-self: center;
  align-self: center;
  text-align: center;
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
}

.lowerPriceInput {
  width: 45%;
  justify-self: center;
  align-self: center;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;

  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
}

.centeredInput {
  border-bottom: none;
  text-align: center;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.calcPriceLabel {
  font-family: Arial, sans-serif;
  font-size: 0.8rem;
  text-align: left;
  line-height: 1.5;
  color: #212529;
  padding: 0;
  font-weight: normal;
}

.priceDiscountIcon {
  margin: auto 0;
}

.priceDiscountText {
  margin: auto 0;
}

.priceDiscountModalButton {
  background-color: #101820;
  padding: 1vw;
}

.priceTooHighErrorMessage {
  font-size: 15px;
  @media (--viewportMedium) {
    font-size: 15px;
  }
}



.isOpen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo semi-transparente */


  /* scrollLayer is the .root for the Modal */

  & .container {
    @apply --marketplaceModalBaseStyles;


    @media (--viewportMedium) {
      flex-basis: 576px;
      min-height: auto;
      height: auto;
    }
  }
}

.scrollLayer {
  height: 100%;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
}


.close {
  @apply --marketplaceModalCloseStyles;
  position: absolute !important;
}


.isClosed {
  display: none;
}
.modalContainer{
  max-width: 760px ;
}