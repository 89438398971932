@import '../../marketplace.css';

.root {
  width: 100%;
  margin-right: 15px;
  align-self: center;
}

.input {
  border-bottom-color: #b2b2b2;
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

/* Tooltip icon */
.tooltipIcon {
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: var(--matterColor);
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -90px;

  /* Font styles */
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
