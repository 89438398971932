@import '../../marketplace.css';

.root {
	/*margin-top: 30px;
	padding: 20px 0;
	@media (--viewportMedium) {
		margin-top: 1px
	}*/
	padding: 0px 0;
}

.title {
	margin: 0 0 30px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	color: #4a4a4a;
}

.favorite {}

.favoriteItem {
	height: 100%;
}