@import '../../marketplace.css';
.referralPageHeader {
    color: var(--gloset-mx-l-vestido-midi-elegante-lady-pipa-654-e-780-f-50-b-3412-c-890-e-585-bdc-25323-c-checkout-390-x-693-default-tundora, #4A4A4A);
    font-family: Inter;
    font-size: 27.07px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -1px;
    width: 100%;
    max-width: 302px;
    margin: auto;
    text-align: center;
    margin-bottom: 10px; /* Spacing between title and icons */
    font-family: CircularStd, Arial, sans-serif;
    @media (max-width: 768px) {
        font-size: 20px; /* Smaller font size on mobile */
        line-height: 28px; /* Adjust line height for mobile */
        text-align: left;
    }
}
.sectionHowItWorks {
    text-align: center;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    font-family: CircularStd, Arial, sans-serif;
    font-size: 27.07px;

    @media (max-width: 768px) {
        text-align: left;
    }

}

.sectionTitle {
    margin-bottom: 20px; /* Spacing after title */
}

.sectionItem {
    display: flex;
    align-items: center;
    justify-content: initial;
    gap: 10px;
    margin-bottom: 15px;
    @apply --marketplaceTinyFontStyles;
}

.sectionItem span {
    display: block;
    text-align: left;
}

@media (max-width: 768px) {
    .sectionItem {
        flex-direction: row;
    }
}

.sectionTitleContainer {
    text-align: center;
    margin: auto;
    max-width: 600px;
    padding: 20px;
}

.iconsContainer {
    display: flex;
    justify-content: center;
    gap: 10px;


    @media (max-width: 550px) {
        justify-content: start;

    }
}

.referralContainer{
    color: var(--gloset-mx-l-vestido-midi-elegante-lady-pipa-654-e-780-f-50-b-3412-c-890-e-585-bdc-25323-c-checkout-390-x-693-default-tundora, #4A4A4A);
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -1px;
    width: 100%;
    max-width: 302px;
    margin: auto;
    text-align: center;
    margin-bottom: 10px; /* Spacing between title and icons */
    font-family: CircularStd, Arial, sans-serif;
    @media (max-width: 768px) {
        font-size: 20px; /* Smaller font size on mobile */
        line-height: 28px; /* Adjust line height for mobile */
        text-align: left !important;
    }
}
