@import '../../marketplace.css';

.root {
  position: relative;
  border-bottom: 1px solid var(--matterColorNegative);
}

.filterLabel,
.filterLabelSelected {
  @apply --marketplaceH3FontStyles;

  /* Baseline adjustment for label text */
  margin-top: 0;
  padding: 4px 0 2px 0;
}

.filterLabel,
.filterLabelSelected {
  @apply --marketplaceH3FontStyles;

  font-size: 20px;
  color: #4a4a4a;
  display: flex;

  /* Baseline adjustment for label text */
  margin: 0;
}

.labelButton {
  /* Override button styles */
   outline: none;
  text-align: left;
  border: none;
  padding: 12px 0 12px 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-self: stretch;

  &:after{
    content: '+';
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    top: 50%;
    left: 0;
    margin-top: -13px;
  }
}

.labelOpen:after {
  content: '-';
}

.formWrapper {
  padding-left: 20px;
}

.clearButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-self: flex-end;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}
