@import '../../marketplace.css';

.staticPageWrapper {
  width: calc(100% - 30px);
  max-width: 1056px;
  margin: 24px auto;
  position: relative;

  @media (--viewportMedium) {
    width: calc(100% - 48px);
  }
}

.bgCircle {
  width: 70%;
  padding-bottom: 70%;
  border-radius: 50%;
  border: solid 1px var(--marketplaceColor);
  position: absolute;
  z-index: -10;

  top: 100px;
  left: -20%;

  @media (--viewportMedium) {
    top: 50px;
    left: -20%;
    width: 50%;
    padding-bottom: 50%;
  }
}

.pageHeader {
  margin-bottom: 20px;
  flex-direction: column-reverse;

  @media (--viewportMedium) {
    margin-bottom: 60px;
    flex-direction: row;
  }  
}

.pageTitle {
  font-family: Kenac;
  color: #8a8a8a;
  font-size: 42px;
  line-height: 1;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 1px;

  @media (--viewportMedium) {
    font-size: 56px;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

.pageSubtitle {
  @apply --marketplaceH2FontStyles;
  margin: 0 0 15px;
  font-size: 20px;
  line-height: 1.1;

  @media (--viewportMedium) {
    margin-bottom: 20px;
    font-size: 25px;    
  }
}

.quote {
  font-style: italic;
  font-size: 18px;
  line-height: 1;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 22px;
  }
}

.quoteIcon {
  color: var(--marketplaceColor);
  font-size: inherit;
  height: 20px;
}

.imageWrapper {
  text-align: center;

  @media (--viewportMedium) {
    text-align: right;
  }
}

.roundImage {
  width: 60%;
  border-radius: 50%;
  object-fit: contain;
  margin: 35px auto 35px;

  @media (--viewportMedium) {
    width: 100%;
    max-width: 300px;
    margin-top: 0;
    margin-right: 15%; 
  }
}

.contentWrapper {
  margin-left: auto;
  
  @media (--viewportMedium) {
    width: 80%;
  }

  & p {
    margin: 0 0 20px; 
    font-size: 20px;

    @media (--viewportMedium){
      font-size: 22px;
      margin: 0 0 30px;
    }
  }
}

.firstLine {
  color: var(--marketplaceColor);
  font-size: 22px;
  @media (--viewportMedium){
    font-size: 24px;
  }
}

.featuredLine {
  color: var(--marketplaceColor);
}

.fillText {
  display: inline-block;
  background-color: var(--marketplaceColor);
  color: white;
  padding: 5px 10px;
}

.subtitle {
  font-family: Kenac;
  color: #8a8a8a;
  font-size: 42px;
  line-height: 1;
  margin-top: 48px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    font-size: 56px;
  }
  
}
