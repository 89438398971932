@import '../../marketplace.css';

.topbar {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.root {
  background-color: #fcfbfb;
}

.content {
  width: 100%;
  min-height: 50vh;
  max-width: 100%;

  @media (--viewportMedium) {
    width: 100%;
  }

  @media (--viewportSmall)  {
    width: 100%;
    max-width:max-content;
    
  }
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  @media (--viewportMedium) {
    min-height: 56px;
    padding-top: 10px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    padding-top: 0;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResults {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 1px;

  @media (--viewportLarge) {
    margin-top: 6px;
  }
}

.itemList {
  margin: 0;
  padding: 0;
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  /* Layout */
  margin-bottom: 10px;
  padding-bottom: 12px;

  border-bottom-style: solid;
  border-bottom-width: 0;
  border-bottom-color: var(--matterColorNegative);

  &:last-child {
    border-bottom: none;
  }

  @media (--viewportLarge) {
    margin-bottom: 10px;
    padding-bottom: 12px;
  }
}

.item {
  display: flex;
  flex-direction: row;
}

.itemLink {
  flex: 1;

  /* Layout */
  display: flex;

  /* Remove text underline from this link */
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }
}

.itemAvatar {
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-top: 2px;
    margin-right: 15px;
  }
}

.rowNotificationDot {
  width: 6px;
  height: 6px;
  margin-top: 10px;
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-right: 13px;
    width: 9px;
    height: 9px;
  }
}

.notificationDot {
  color: var(--matterColorLight);

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--failColor);
}

.itemInfo {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Layout */
  flex-grow: 1;
  flex: 1;
  flex-direction: column;

  margin-top: 5px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 3px;
    margin-bottom: 0;
  }

  color: var(--matterColor);
}

.itemUsername {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  line-height: 18px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingInfoWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  font-size: 14px;
  line-height: 14px;
  margin-top: 2px;
  padding-top: 2px;

  @media (--viewportMedium) {
    padding-top: 0px;
    margin-top: 8px;
    line-height: 16px;
  }
}

.itemPrice {
  &::before {
    font-size: 10px;
    margin: 0 6px;
    display: inline-block;
    content: '\25CF'; /* middot */
  }
}

.itemState {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);
  margin-top: 6px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.lastTransitionedAt {
  text-align: right;
  color: var(--matterColor);
}

.pagination {
  margin: auto 24px 0 24px;
}

/* Transaction status affects to certain font colors and weights */

.stateName {
  /* This class is empty on purpose, it is used below for banned users */
}

.stateActionNeeded {
  font-weight: var(--fontWeightSemiBold);
  color: var(--attentionColor);
}

.stateNoActionNeeded {
  color: var(--matterColorAnti);
}

.stateSucces {
  font-weight: var(--fontWeightSemiBold);
  color: var(--successColor);
}

.nameEmphasized {
  font-weight: var(--fontWeightBold);

  /* baseline alignment fixes */
  margin-top: -1px;
  margin-bottom: 1px;
}

.nameNotEmphasized {
  font-weight: var(--fontWeightMedium);
}

.bookingActionNeeded {
  color: var(--matterColor);
  margin-top: 4px;

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.bookingNoActionNeeded {
  color: var(--matterColorAnti);
}

.lastTransitionedAtEmphasized {
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
}

.lastTransitionedAtNotEmphasized {
  color: var(--matterColorAnti);
}

.bannedUserLink {
  & .itemUsername,
  &:hover .itemUsername,
  & .bookingInfo,
  & .stateName,
  & .lastTransitionedAt {
    color: var(--matterColorAnti);
  }
}

.orderContainer {
  background-color: white;
  color: black;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  font-size: 16px !important;

  padding: 0;

  @media (--viewportSmall) {
    padding: 25px 35px 25px 35px;
  }
}

.orderContaier p {
  font-size: 16px !important;
}

.orderContainer a, .orderContainer a:hover, .orderContainer a:visited, .orderContainer a:active {
  color: black;
  text-decoration: none;
}

.unreadMessageContainer{
  background-color: white;
  color: black;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  font-size: 16px !important;

  background-color: #EFEFEF;

  padding: 0;

  @media (--viewportSmall) {
    padding: 25px 35px 25px 35px;
  }
}

.unreadMessageContainer p {
  font-size: 16px !important;
}

.unreadMessageContainer a, .unreadMessageContainer a:hover, .unreadMessageContainer a:visited, .unreadMessageContainer a:active {
  color: black;
  text-decoration: none;
}

.avatarMobile{
  align-self: center;
  margin-left: 5px;
}

p.orderDetail, p.orderDetailPrice {
  font-size: 16px !important;
  margin: 0;
  white-space: nowrap; 
  width: 15vw; 
  overflow: hidden;
  text-overflow: ellipsis; 
  min-width: calc(100%);
}

p.orderDetailUnread, p.orderDetailPriceUnread {
  font-size: 16px !important;
  margin: 0;
  white-space: nowrap; 
  width: 15vw; 
  overflow: hidden;
  text-overflow: ellipsis; 
  min-width: calc(100%);

  font-weight: bold;
}

.orderDetailArrow{
  align-self: center;
  margin-left: 0;

  @media (--viewportSmall) {
    margin-left: 10px;
  }
}

p.orderDetailPrice {
  color: #fd7c67;
  font-weight: bold;
}

p.orderDetail span.orderDetailUser {
  font-weight: bold;
}

p.orderDetail span.orderDetailLastMessage {

}

.orderDetailListingImage{
  align-self: center;
  height: fit-content;
}

.orderDetailRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.orderDetailPicture {
  width: 100%;
  float: left;

  @media (--viewportSmall) {
    width: 10%;
  }
}

.orderDetailPayment {
  width: 100%;
  float: left;

  @media (--viewportMedium) {
    width: 35%;
  }
}

.orderDetailPayment {
  color: #b2b2b2;
}

.orderDetailPayment span {
  color: black;
}

.fullWidth {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.orderDetailFlex{
  display: flex;
  flex-grow: 1;
}

.orderDetailListing {
  margin-left: 10px;
  margin-right: auto;
  align-self: center;

  width: 100%;

  @media (--viewportSmall) {
    margin-left: 40px;
    
  }
}

.orderDetailTimeStamps{
  align-self: flex-end;
  margin-bottom: 22px;
  min-width: 105px;
  text-align: right;
  margin-right: 10px;

  font-size: 14px !important;


  color: grey;

  font-style: italic;

  @media (max-width: 768px) {
  margin-bottom: 15px;
    
  }
}

.orderDetailStatus {
  margin-top: 30px;
  text-transform: uppercase;
  font-weight: bold;
}

.orderHasTracking {
  margin-top: 30px;
  font-weight: bold;
  text-transform: uppercase;
}

.orderHasTracking span {
  color: #fd7c67;
}

.orderHasCaseStatus {
  margin-top: 30px;
  color: #b2b2b2;
}

.orderHasCaseStatus span {
  color: #fd7c67;
}

.startCase:hover {
  cursor: pointer;
}

.rejected {
  color: red;
}

.completed {
  color: #01be6d;
}

.pending {
  color: #ff8a00;
}

.inboxTitle {
  font-weight: bold;
  font-size: 36px;
}

.setCompleted {
  padding: 15px 25px !important;
  line-height: 8px;
  font-size: 12px;
  border-radius: 50px;
  min-height: 40px;
  margin-top: 20px;
}

.trackingUrl, .trackingUrl:visited, .trackingUrl:hover {
  color: #fd7c67 !important;
}

.setCompletedLabel {
  font-size: 14px;
  line-height: 15px;
  color: #b2b2b2;
  padding-top: 15px;

  @media (--viewportMedium) {
    width: 60%;
    margin-left: 15px;
    padding-top: 25px;
  }
}

.messageNotificationDot {
  /* Dimensions */
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;

  /* Position */
  position: relative;
  top: 5px;
  right: 5px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;

  @media (--viewportSmall) {
    top: 0;
    right: 0;
  }
}

.messageNotificationDotHidden{
    /* Dimensions */
    width: 10px;
    height: 10px;
  
    /* Position */
    position: relative;
    top: 5px;
    right: 5px;

    visibility: hidden;
  
    @media (--viewportSmall) {
      top: 0;
      right: 0;
    }
}
