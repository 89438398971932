@import '../../marketplace.css';

.parentContainer{
    margin-bottom: 1vh;
}

.shippingContainer{
    display: flex;
    flex-direction: column;
}

.shippingItem{
    display: flex;
}

.shippingItemImage{
    width: 15%;
}

.logoImages{
    width: 15%;
}

.heading {
    margin-top: 29px;
    margin-bottom: 34px;
    padding: 0 24px;
  
    @media (--viewportMedium) {
      margin-top: 10px;
      margin-bottom: 18px;
    }
  
    @media (--viewportLarge) {
      margin-top: 0px;
      margin-bottom: 24px;
      padding: 0;
    }
  }

.expressShippingNote{
    font-size: 17px;
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    margin: auto;
    margin-left: 5vw;
    font-family: Arial, sans-serif;

    border: 1px solid #ffaa00;
    background: #fff7f0;

    @media (--viewportSmall) {
        margin-left: 5px;
        flex-direction: row;
    }

    @media (--viewportMedium) {
        margin-left: 15px;
    }
}

.expressShippingNoteInvisible{
    display: none;
}

.expressShippingMessageBodyContainer{
    flex: 75%;
    display: flex;
    flex-direction: column;
    padding: 20px 0px 15px 10px;
    font-size: 14px;

    @media (--viewportSmall) {
        padding: 20px;
    }
}

.expressShippingMessageTitle{
    font-weight: bold;
}

.expressShippingMessage{
    

    @media (--viewportSmall) {
        margin-left: 5px;
    }
}

.expressShippingMessageButton{

    font-size: 0.7rem;

    margin: 0px auto 12px 10px;
    padding: 10px;
    flex: 25%;
    background-color: #fd7c67;
    color: #ffffff;
    font-weight: bold;
    border: none;
    

    @media (--viewportSmall) {
        margin: 20px;
    }

    @media (--viewportMedium) {
        margin: 20px;
    }

    &:hover{
        background-color: #8c291e;
    } 
}

.title {
    /* Font */
    @apply --marketplaceH2FontStyles;
    color: var(--matterColor);
  
    /* Layout */
    width: 100%;
    margin-top: 0;
    margin-bottom: 7px;
    overflow-x: auto;
    word-break: break-all;
  
    @media (--viewportMedium) {
      font-size: 30px;
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 5px;
    }
        
}

.titleInvisible{
    display: none;
}

.messageLink{
  /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: underline;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}