@import '../../marketplace.css';

:root {  }

.root {
	padding: 38px 24px;

	@media (--viewportMedium) {
		padding: 60px 160px 130px;
	}
}

.title {
	display: inline-flex;
	align-items: center;
	font-size: 28px;
	margin: 0 0 38px;
	line-height: 1;
	font-weight: normal;

	@media (--viewportMedium) {
		margin: 0 0 48px;
	}
}

.titleIcon {
	margin-left: 6px;
	width: 25px;
	height: 25px; 
}

.loader {
	color: var(--marketplaceColor);
}

.favoritedItem {
	height: 95%;
	flex-basis: calc(50% - 16px);
	margin-bottom: 16px;
	margin-right: 16px;
	@media (--viewportLarge) {
		flex-basis: calc(33% - 16px);
	}
}

.listingCards {
	flex-grow: 1;
	display:flex;
	flex-direction: row;
	padding: 0;
	flex-wrap: wrap;
  }
