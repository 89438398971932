@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
  background-color: #FCFBFB;
}

.label{
    @apply --marketplacePrincipalLabel;
    text-align: center;
  }
.label1{
    text-align: center;
    font-size:medium;
    color: var(--marketplaceColor);
  }
.title{
  @apply --marketplacePrincipalLabel;
  text-align: center;
  font-size:xx-large;
  color: var(--marketplaceColor);
}

  .bottomWrapper {
    @apply --marketplaceModalBottomWrapper;
    @media (--viewportMedium) {
      margin-top: 50px;
    }
  }
  
  .bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;
    line-height: 1.2;
  }
  
  .termsText {
    @apply --marketplaceModalHelperText;
  }