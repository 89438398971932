@import '../../marketplace.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
  padding: 48px 24px;
}

.aside {
  box-shadow: none;
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    flex-direction: column;
    border-bottom: none;
  }
}

.avatar {
  margin: 30px 26px 29px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    margin: 0 96px 44px 0;
  }
}

.mobileHeading {
  flex-shrink: 0;
  margin: 47px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 2px 0 24px 0;
  }
}

.heading {
  width: 100%;
}

.heading__cover {
  height: 303px;
  max-height: 20vh;
  background-color: #f3f3f3;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (--viewportMedium) {
    max-height: 40vh;
  }
}

.heading__content {
  padding: 0 32px;
}

.heading__user {
  text-align: center;
  position: relative;
  top: -70px;
  margin-bottom: -70px;
  @media(--viewportMedium) {
    top: -110px;
    margin-bottom: -110px;
  }
}

.heading__avatar {
  border: solid 2px white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  margin: 0 auto;

  @media(--viewportMedium) {
    width: 150px;
    height: 150px;
  }
}

.heading__name {
  font-size: 28px;
  line-height: 1;
  margin: 18px 0 16px;
  @media(--viewportMedium) {
    font-size: 32px;
  }
}

.heading__info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 28px 0;
  @media(--viewportMedium) {
    margin: 45px 0 25px;
  }
}

.infoItem p {
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  text-align: center;
}

.infoItem.infoItem__clickable:hover {
  cursor: pointer;
  color: var(--marketplaceColor);
}

.infoItem .infoItem__label {
  font-size: 14px;
}

.followButton {
  line-height: 1.2;
  margin: 0 auto;
  min-height: unset;
  width: auto;
  padding: 7px 15px;
  font-size: 15px;  
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  @apply --clearfix;
  border-top: 1px solid var(--matterColorNegative);
  padding-bottom: 23px;
  padding: 0 32px 23px;

  @media (--viewportMedium) {
    border-top: none;
    padding-bottom: 22px;
  }
}

.listingsTitle {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    margin-top: 48px;

  }
  @media (--viewportLarge) {
    font-size: 14px;
    margin-top: 58px;
  }
}

.listingsList {
  flex-basis: 100%;
  max-width: 100%;
  flex-grow: 0;
  @media (--viewportMedium) {
    flex-basis: 80%;
    max-width: 80%;
  }
}

.listingsFilters {
  flex-basis: 100%;
  max-width: 100%;
  flex-grow: 0;
  @media (--viewportMedium) {
    flex-basis: 20%;
    max-width: 20%;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--matterColorNegative);
  }
}

.listings {
  margin: 0;
}

.listingCard {
  height: 100%;
}

.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
}

.desktopReviews {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    max-width: 600px;
    margin-bottom: 0;
  }
}

.desktopReviewsTitle {
  @apply --marketplaceH3FontStyles;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

/** SORT COMPONENT **/
.sortBy {
  margin-right: 9px;
}

.sortByDesktop {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.modalContainer{
  overflow-x: hidden !important;
  max-height: 90% !important;
  margin: 0 15px;
}

/** LISTINGS LAYOUT **/

.searchResultContainer {
  width: 100%;
  padding: 12px 18px;
  @media (--viewportLarge) {
    padding: 23px 32px;
  }
}

/** SEARCH FILTERS **/
.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 21px;
  }
}

.searchFiltersMobile {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.topSection {
  margin-bottom: 18px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.loader {
  color: var(--marketplaceColor);
}

.reviewAuthor {
  margin: 0;
}

.followName {
  margin: 0;
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;

@media (--viewportMedium) {
  max-width: 500px;
  margin: 7px 0 0 0;
}
}

.reviewStars {
  display: block;
  margin-top: 4px;

@media (--viewportMedium) {
  margin: 5px 0;
}
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}


.followBtnList {
  border-radius: 40px;
  height: 35px;
  min-height: 10px;
  font-weight: normal;
  font-size: 15px;
}

.modalTitle {
  font-weight: bold;
  font-size: 20px;
}

.modalError {
  font-size: 14px;
  color: var(--failColor);
  line-height: 1.2;
}