@import '../../marketplace.css';

.root {
}

.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}


.react-select{
  color: #212529 !important;
  border: hidden !important;
  border-bottom: 2px solid var(--attentionColor) !important;
  padding-right: 20px;

  & input{
    padding: 4px 0 10px 0 !important;
    font-size: 1rem !important;
    margin-left: 0px !important;   
  }

}