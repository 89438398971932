@import '../../../marketplace.css';

.topbar {
  z-index: 1;
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.titleWrapper {
  margin: 63px 0 48px;
  padding: 0 28px;
  @media (--viewportLarge) {
    width: 60%;
    margin: 63px auto 48px;
    padding: 0;
  }
}

.pageTitle {
  margin: 0 0 12px;
  font-size: 32px;
  font-weight: normal;
  color: black;
  letter-spacing: -0.1px;
}

.helpSubtitle {
  margin: 0;
  font-size: 16px;
  line-height: 1.1;
  font-weight: normal;
  color: var(--matterColor);
  letter-spacing: -0.1px;
}

.wizard {
  flex-grow: 1;
}
