.skeleton-card {
    border: hidden solid #ddd;
    padding: 16px;
    margin: 16px;
    border-radius: 8px;
    width: 300px;
    display: flex;
    flex-direction: column;
  }
  
  .skeleton-image {
    height: 150px;
    background-color: #eee;
    border-radius: 4px;
    margin-bottom: 12px;
  }
  
  .skeleton-text {
    height: 20px;
    background-color: #eee;
    border-radius: 4px;
    width: 80%;
  }