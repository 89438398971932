@import '../../marketplace.css';

.root {
  position: relative;
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.filterLabel,
.filterLabelSelected {
  @apply --marketplaceH3FontStyles;

  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #4a4a4a;
  font-weight: normal;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
  padding-right: 20px;
  position: relative;

  &:after{
    content: '+';
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    top: 0px;
    right: 0;
  }
}

.labelOpen:after {
  content: '-';
}