@import '../../marketplace.css';

.root {
  outline: none;
}

.contentWrapper {
  margin-bottom: 10px;
}

.buttonsWrapper {
  display: flex;
  padding: 0 10px 16px
}

.buttonsWrapperTop {
  display: flex;
  justify-content: space-between;
}

.clearButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 4px 8px;

  /* Override button styles */
  outline: none;
  border: solid 1px var(--marketplaceColor);
  cursor: pointer;
  border-radius: 4px;

  &:focus,
  &:hover {
    color: white;
    background-color: var(--marketplaceColor);
    transition: width var(--transitionStyleButton);
  }
}
