@import '../../marketplace.css';

.root {
  background-color: #fcfbfb;
}
.content {
  width: 100%;

  @media (--viewportMedium) {
    max-width: 60vw;
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
}

.heading {
  font-size: 36px;
  font-weight: normal;
  line-height: 38px;
  color: #000;

  margin: 0;
  hyphens: auto;
}

.profileLink {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;

  display: inline-block;
  width: auto;
  min-height: auto;
  height: 41px;

  float: right;
  flex-shrink: 0;

  margin: 19px 0 0 auto;
  padding: 9px 16px 8px 16px;

  @media (--viewportMedium) {
    margin: 37px 0 0 auto;
  }
}

.formWrapper {
  @media (--viewportMedium) {
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
  }
}
