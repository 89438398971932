@import '../../marketplace.css';

.balanceContainer{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.balanceRow{
    display: inline-flex;
    padding: 0.625rem 1.25rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E7E7E7;
    background: var(--neutral-white, #FFF); 
    width: 100%;
}

.transferBalanceButton{
    display: flex;
    padding: 0rem 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
    border: 1px solid #FF7262;
    font-family: Inter;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.875rem; /* 230.769% */
    letter-spacing: -0.01875rem;
    background: var(--gloset-mx-l-vestido-midi-elegante-lady-pipa-654-e-780-f-50-b-3412-c-890-e-585-bdc-25323-c-checkout-390-x-693-default-linear-aqua-deep-bittersweet, linear-gradient(180deg, #FF4C38 0%, #FD7C67 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: calc(100% - 40px);
    text-align: center;
    margin: 20px;

    @media (--viewportLarge) {
       width: 320px; 
       margin-left: auto;
       margin-right: auto;

      }
}

.transferBalanceButton:hover{
  @media (--viewportLarge) {
   }
}

.transferBalanceButton:disabled{
  opacity: 0.5;
  cursor: not-allowed;
}

.referredUsersContainer{
    display: inline-flex;
    padding: 0.625rem 1.25rem;
    justify-content: space-between;
    align-items: center;
    background: var(--neutral-white, #FFF);
    width: 100%;
}

.payoutDataContainer{
    display: inline-flex;
    padding: 0.625rem 1.25rem;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #E7E7E7;
    background: var(--neutral-white, #FFF);
    width: 100%;
    cursor: pointer;
}

.referralContainer{
    display: inline-flex;
    padding: 0.625rem 1.25rem;
    justify-content: space-between;
    align-items: center;
    background: var(--neutral-white, #FFF);
    width: 100%;
    border-top: 1px solid #E7E7E7;

    cursor: pointer;
}

.historyAndPayoutContainer{
    margin-top: 10vw;
    @media (--viewportLarge) {
    margin: 0px; 
    }

}


.payoutHistoryDataContainer{

}

.payoutDataRow{
    display: flex;
    padding: 0rem 1.25rem;
    align-items: center;
    align-self: stretch;
    border-top: 1px solid #E7E7E7;
    background: var(--neutral-white, #FFF);
    justify-content: space-between;

    color: var(--gloset-mx-l-vestido-midi-elegante-lady-pipa-654-e-780-f-50-b-3412-c-890-e-585-bdc-25323-c-checkout-390-x-693-default-tundora, #4A4A4A);
    font-family: Inter;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.875rem; /* 230.769% */
    letter-spacing: -0.01875rem;
    font-family: CircularStd;

}

.closeText {
    @apply --marketplaceModalCloseText;
    color:#B2B2B2;
}

.closeIcon {
    @apply --marketplaceModalCloseIcon;
    stroke: #B2B2B2;
    fill: #B2B2B2;
}

.closeButtonClasses {
    z-index: calc(100 + 1);
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content:'center';

    width: auto;
    /* padding: 6px; */
    margin: 0;
    border: 0;
}

.containerModalPayout{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.payoutButton{
	display: flex;
	padding: 0rem 0.625rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	flex: 1 0 0;
  width: 170px !important ;
}

.payoutHistoryTitle{
    display: flex;
    padding-left: 20px ;
}

.modal {
    display: block;
    position: fixed;
    left: 0px;
    height: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 100;
    


  
    /* scrollLayer is the .root for the Modal */
    & .scrollLayer {
      @apply --marketplaceModalRootStyles;
  
      /* Add default background color to avoid bouncing scroll showing the
     page contents from behind the modal. */
      background-color: var(--matterColorLight);
  
      /* Additional styles for the modal window, dimming the background and positioning the modal */
      min-height: 100vh;
      min-height: -webkit-fill-available;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
  
      @media (--viewportMedium) {
        padding: 0;
        background-color: rgba(0, 0, 0, 0.4);
        background-image: none;
      }
    }
  
    & .container {
      @apply --marketplaceModalBaseStyles;
      min-height: 100vh;
      height: 100%;
    	padding: 5%;

  
      @media (--viewportMedium) {
        flex-basis: 576px;
        min-height: auto;
        height: auto;
      }
    }
  }

.close {
    @apply --marketplaceModalCloseStyles;
    padding: 0px;
    right: 15px  !important;
    top: 10px  !important;

    position: absolute !important;
		
}

.containerWarning{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.textHistoryData{
font-size: 13px;
font-weight: 600;
line-height: 30px;
text-align: left;
}

.textStatus-pending, .textStatus-paid, .textStatus-failed {
  position: relative;
  padding-left: 10px;
  display: flex;
  align-items: center;
  width: 5rem;
}

.textStatus-pending::after{
  content: ' ';
  width: 6px;
  height: 6px;
  background: #FBBC05;
  position: absolute;
  border-radius: 50%;
  left: 0px;
}


.textStatus-paid::after{
  content: ' ';
  width: 6px;
  height: 6px;
  background: #34A853;
  position: absolute;
  border-radius: 50%;
  left: 0px;
}


.textStatus-failed::after{
  content: ' ';
  width: 6px;
  height: 6px;
  background: red;
  position: absolute;
  border-radius: 50%;
  left: 0px;
}

.containerClassName{
  position: relative;
}

.customCloseIconClass{
  position: absolute;
}

.containerBalance{
  @media (--viewportLarge) {
    border-radius: 10px; /* Radio de borde para hacer los bordes redondeados */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
   transition: box-shadow 0.3s ease; /* Transición suave para la sombra */
    padding: 15px 30px;
    }
}

.containerModalInformation{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:10px;
}

.containerModal{
  margin-top: auto;
  margin-bottom: auto;
  background: #F5F2EE;
  position: relative;
  padding: 15px 37px ;
}

.closeButtonClassName{
  padding: 0px !important;
  right: 15px !important;
  top: 10px !important;
  position: absolute !important;
  
}


.payoutText{
  font-family: CircularStd;
  font-size: 13px;
  font-weight: 600;
  line-height: 23px;
  text-align: center;
}

.textInformation{
font-size: 13px;
font-weight: 600;
line-height: 18px;
text-align: center;
color:#4A4A4A;
}

.containerPagination{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}