@import '../../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingPhotosForm_imageWidthDesktop: calc(20% - 12px);
  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.title {
  @apply --EditListingDescriptionForm_formMargins;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.formSection {
  padding: 28px;
  margin-bottom: 20px;
  position: relative;

  @media (--viewportMedium) {
    margin-bottom: 45px;
  }
}

.formSectionTitle {
  margin-bottom: 28px;
  font-weight: normal;
  font-size: 19px;
  text-transform: uppercase;
  letter-spacing: -0.1px;
  position: relative;

  @media (--viewportLarge) {
    margin-bottom: 0px;
    margin-top: 43px;
    line-height: 1;
    padding: 0;
    position: absolute;
    width: 18vw;
    left: -18vw;
    top: 0;
  }
}

.photosSectionTitle {
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 19px;
  text-transform: uppercase;
  letter-spacing: -0.1px;
  position: relative;
}

.photosSectionSubtitle {
  margin: 0;
  font-weight: normal;
  color: var(--attentionColor);
  font-size: 16px;
  position: relative;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.datalist {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.datalistSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.datalistError {
  border-bottom-color: var(--failColor);
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: 100%;
    margin: 0 0 24px 0;
  }
}

.thumbnail {
  margin: 24px 0 0 0;
  flex-basis: calc(30% - 12px);
  margin-right: 8px;
  margin-left: 8px;

  @media (--viewportSmall) {
   /* width: var(--EditListingPhotosForm_imageWidthDesktop);*/
   flex-basis: calc(24% - 12px);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  /*padding-bottom: calc(100% * (3 / 10));*/
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.formFlex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flexItem {
  margin-bottom: 37px;
  width: 100%;
  @media (--viewportLarge) {
    width: 48%;
  }
}

.submitButton {
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
  }
}

/** RELOVE DROPZONE **/

.dropzoneBase {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 53px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #e6e6e6;
  border-style: dashed;
  color: #8f8f8f;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzoneActive {
  border-color: var(--marketplaceColor);
}

.dropzoneAccept {
  border-color: var(--successColor);
}

.dropzoneReject {
  border-color: #ff1744;
}

.dropzoneButton {
  background-color: #f2f0f0;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  font-size: 14px;
  padding: 12px 16px;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.15);
  }
}

.dropzoneSmallText {
  margin: 13px 0 0;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
}

.dropzoneSpecsText {
  color: #cbcbcb;
  margin: 5px 0 0;
  font-size: 12px;
  line-height: 1;
}

.dropzoneThumbnails {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/** READ ONLY FIELD **/
.readOnlyField {
  border-bottom-color: #e5e1e6;
  cursor: default;
  &:hover, &:focus {
    border-bottom-color: #e5e1e6;
  }
}
.profitRow {
  width: 100%;
  @media (--viewportLarge) {
    justify-content: flex-end
  }
}

/** COLOR FIELD **/
.colorContainer {

  & :global(.rw-widget-picker){
    height: unset;
  }

  & :global(.rw-state-focus>.rw-widget-container) {
    box-shadow: none;
    border-color: var(--matterColor);
  }
}


.color {
  @apply --marketplaceInputStyles;
  border-bottom-color: var(--attentionColor);

  box-shadow: none;

  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }

  & :global(.rw-multiselect-tag) {
    background-color: transparent;
    height: unset;
    padding: 3px .35em;
    display: inline-flex;
    align-items: center;
  } 
  & :global(.rw-input-reset) {
    display: inline-block;
    height: unset;
  }
}

.colorSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.colorError {
  border-bottom-color: var(--failColor);
}

.colorOption {
  border-radius: 50%;
  border: 1px solid #dadada;
  height: 26px;
  width: 26px;
  display: block;
}

.colorItem {
  display: flex;
  align-items: center;
}

/** SIZE CUSTOM FIELD **/
.sizeContainer {

  & :global(.rw-widget-picker){
    height: unset;
  }

  & :global(.rw-state-focus>.rw-widget-container) {
    box-shadow: none;
    border-color: var(--matterColor);
  }
}

.size {
  @apply --marketplaceInputStyles;
  border-bottom-color: var(--attentionColor);

  box-shadow: none;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
  }
  & :global(.rw-multiselect-tag) {
    background-color: transparent;
    font-size: 14px;
  }
  & :global(.rw-input-reset) {
    display: inline-block;
    height: unset;
  } 
}

.sizeSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.sizeError {
  border-bottom-color: var(--failColor);
}

.placeholderOption {
  color: #b2b2b2 !important;
}

/* option of combo select    state  */
.itemState{
  color: #4A4A4A;
  & > div {
    padding: 10px 8px;
    transition: background-color 0.25s ;

    & > h6{
      margin: 0;


    }
  
    & > p {
      font-size: 12px !important;
      margin: 0px !important;
      margin-top: 6px !important;
      line-height: 20px !important;

    }
  
  }

}

.itemState :hover{
   
  background: #FD7C67;
    color: white;
  
}

.itemSelected{
  background: #FD7C67;
  color: white;
}


.dropdownContainer {
  position: relative;
}

.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 0;
  overflow-y: auto;
  opacity: 0;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  z-index: 10;
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}


input::placeholder {
  color: var(--matterColorAnti);
  opacity: 1;
}

.dropdown {
  border-bottom-color: var(--attentionColor);
}

.showDropdown {
  max-height: 300px;
  opacity: 1;
}

.subCategoryContainer{
  width: 100%;
  margin-bottom: 37px;
  @media (--viewportLarge) {
    width: 48%;
  }
}

.comboSelect {
  margin-bottom: 37px;
  width: 100%;
  @media (--viewportLarge) {
    width: 48%;
  }
}

.comboSelect .error {
  color: var(--failColor); /* Consistent with other error messages */
  font-size: 12px;
  margin-top: 4px;
}

.comboSelect .placeholderOption {
  color: #b2b2b2 !important;
}

.validationError {
  @apply --marketplaceH4FontStyles;
  margin-top: 6px;
  margin-bottom: 12px;
  color: var(--failColor);
  overflow: hidden;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
}
