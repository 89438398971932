
@import '../../marketplace.css';


.containerModalCondition{
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    @apply --marketplaceModalRootStyles;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--matterColorLight);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    @apply --marketplaceModalBaseStyles;
    min-height: 100vh;
    height: 100%;

    @media (--viewportMedium) {
      flex-basis: 576px;
      min-height: auto;
      height: auto;
    }
  }
}

.containerModalScroll {
    padding-top: 120px;
    padding-bottom: 120px;
    height: 100%;

    @media (--viewportMedium) {
      padding: 0px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    };
    

}

.containerModal{
    position: relative;
    padding: 47px 22px;
    height: 100% ;
    background: #F5F2EE;

    width: auto;
    height: auto;


    @media (--viewportMedium) {
      width: 50%;

    };

}

.closeButton{
    @apply --marketplaceModalCloseStyles;
    position: absolute;
}

.titleModalCondition{
    font-size: 14px;
    margin-bottom: 0px !important;
}

.descriptionModalCondition{
    font-size: 12px !important;
    line-height: 18px !important;
    margin-top: 0px ;
}



/*

@media (--viewportSmall) {
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;   
    
  }

*/