@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarMobileMenu_topMargin: 50px;
}

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  font-family: CircularStd;
  height: calc(100% -  104px);

}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin:  0px;
  width: 73px;
  height: 73px;
}

.greeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  margin-bottom: 1px;
  margin-top: 16px;
  font-size: 22px;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Logout font is smaller and gray since the action is not recommended. */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 6px 0;
  padding: 0;
}

.blogLink {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  position: relative;

  margin-top: 50px;
  margin-bottom: 13px;
}

.notifsLink {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: 0;
  margin-bottom: 13px;
}

.notificationDot {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.ordersLink {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: 0;
  margin-bottom: 13px;
  color: #4A4A4A !important;
}

.inbox {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: 0;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
  font-size: 22px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  @apply --marketplaceButtonStyles;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}



.separator{
  height: 1px;
  margin: 0px;
  background: #e0e0e0;
  width: 100%;
  border: none;
}

.containerHeaderMenu{
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 0px;
  gap: 25px;
}

.containerTextHeader{
  display: flex;
  flex-direction: column;
}

.textColorDark{
  color: #4A4A4A !important;
  display: flex;
  align-items: center;
  gap :10px;
  font-weight: 500;
  font-size: 21px;
}