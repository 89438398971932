@import '../../marketplace.css';

:root {
}

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}


.sectionContainer {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightNormal);
  font-size: 24px;
  line-height: 26px;


  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.actionsContainer {
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}


.address {
  width: calc(50% - 9px);
}

.addressExtNumber {
  width: calc(25% - 9px);
}

.addressIntNumber {
  width: calc(25% - 9px);
}

.addressNeighborhood {
  width: calc(70% - 9px);
}

.addressZip{
  width: calc(30% - 9px);
}

.addressState {
  width: calc(50% - 9px);
}

.addressCity {
  width: calc(50% - 9px);
}
.phone {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.submitButton {
  margin-top: 24px;
}