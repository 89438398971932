@import '../../marketplace.css';

.root {
  margin-top: 100px;
}

.title {
  @apply --marketplaceH1FontStyles;

  font-weight: normal;
  font-size: 42px;

  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.subtitle {
  @apply --marketplaceH2FontStyles;

  font-weight: normal;
  
  margin-top: 0;
  text-align: center;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0 15%;
  }
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }

  &:after {
    display: block;
    margin-top: 3px;
    content: '';
    width: 100%;
    height: 12px;
  }
}

.stepTitle1:after {
  background-color: rgb(2, 224, 181);
}

.stepTitle2:after {
  background-color: rgb(207, 241, 254);
}

.stepTitle3:after {
  background-color: rgb(0, 13, 107);
}

.stepImage {
  width: 100%;
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.stepContent {
  font-weight: 500;
  color: var(--matterColor);
  & > li {
    margin-bottom: 12px;
  } 
}

.createListingLink {
  margin-top: 36px;

  @media (--viewportMedium) {
    text-align: center;
  }
}
