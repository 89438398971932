@import '../../../marketplace.css';

.root {
  position: relative;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid #f2f2f2;
    border-radius: 10px;
    background-color: #d4d4d4
  }
}



.fieldGroupPlain {
  margin: 0;
  padding-left: 20px;
  padding-bottom: 30px;
  border: none;

  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid #f2f2f2;
    border-radius: 10px;
    background-color: #d4d4d4
  }
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.searchGroup {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.searchIcon {
  stroke: #000;
  margin-right: 5px;
  height: 18px;
}

.brandSearch {
  font-size: 15px;
  padding: 5px 0;
  border: none;
}
