@import '../../marketplace.css';

:root {
  --AuthenticationPage_desktopTitleMaxWidth: 625px;

  --AuthenticationPage_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }

}

.root {
  @apply --marketplaceModalRootStyles;

  @media (--viewportMedium) {
    border-radius: 0;
    border: none;
  }
}

.formContainer{
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.content {
  @apply --marketplaceModalBaseStyles;
  border-bottom: none;
  margin: 0;
  align-self: center;

  @media (--viewportMedium) {
    padding: 55px 60px 55px 10px;  
  }
}

.signupButton{
  text-align: center;
  margin-top: 15px;
}

.heroContent {
  padding-top: 20px;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}
.heroForm {
  align-self: flex-start;
}
.heroMainTitle {
  font-size: 38px;
  font-weight: normal;
  color: var(--matterColorDark);

  margin: 0 0 16px;

  @apply --AuthenticationPage_animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    font-size: 42px;
  }
}

.heroSubTitle {
  @apply --marketplaceBodyFontStyles;

  font-size: 16px;

  color: var(--matterColorDark);
  margin: 0 0 32px 0;

  @apply --AuthenticationPage_animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    font-size: 18px;
    max-width: var(--AuthenticationPage_desktopTitleMaxWidth);
    margin: 0 0 57px 0;
  }
}


/* ================ Tabs & Form ================ */

.tabs {
  @media (--viewportMedium) {
    margin-top: 12px;
    margin-bottom: 25px;
  }
}

.tab {
  margin-right: 15px;
  color: black;
}

.tabSelected{
  margin-right: 15px;
  color: black;
  font-weight: bold;
}

.label{
  @apply --marketplacePrincipalLabel;
  margin-top: 24px;
  font-size: 26px;
  text-align:left;
  color: var(--marketplaceColor);
}

.noBottomBorder a{
  border-bottom: none;
}

.form {
  /* margin-top: 10px; */

  @media (--viewportMedium) {
    margin-top: 0px;
    padding-top: 0px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.termsHeading {
  @apply --marketplaceH1FontStyles;
  margin: 0 0 19px 0;
  font-size: 40px;

  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  @apply --marketplaceModalCloseStyles;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  @apply --marketplaceModalErrorStyles;
}

.returnInline{
  float: right;
  font-size: 16px;
  width: fit-content;
  color: gray;
}


.isOpen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo semi-transparente */


  /* scrollLayer is the .root for the Modal */

  & .container {
    @apply --marketplaceModalBaseStyles;

    flex-basis: 100%;
    min-height: auto;
    height: auto;


    padding: 0;
    
    @media (--viewportLarge) {
      flex-basis: 60%;
      min-height: auto;
      height: auto;
    }

    @media (--viewportXLarge) {
      flex-basis: 40%;
      min-height: auto;
      height: auto;
    }
  }
}

.scrollLayer {
  height: 100%;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
}


.close {
  @apply --marketplaceModalCloseStyles;
  position: absolute !important;
}


.isClosed {
  display: none;
}

.imageSrc{
  display: none;

  @media (--viewportMedium) {
    display: block;
    width: 50%;
  }
}