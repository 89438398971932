@import '../../marketplace.css';


.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  height: 15rem;
  right: 5%;
  bottom: 15%;
  left: 5%;
  margin: auto;
  max-width: 50rem;
  z-index: 100;
  background-color: #f5f2ee;
  text-align: center;

  color: var(--gloset-mx-l-vestido-midi-elegante-lady-pipa-654-e-780-f-50-b-3412-c-890-e-585-bdc-25323-c-checkout-390-x-693-default-tundora, #4A4A4A);
  text-align: center;
  font-family: Inter;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4375rem; /* 176.923% */
  letter-spacing: -0.01875rem;

  justify-content: center;
  padding: 0 1rem;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    @apply --marketplaceModalRootStyles;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--matterColorLight);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    @apply --marketplaceModalBaseStyles;
    min-height: 100vh;
    height: 100%;
    padding: 5%;


    @media (--viewportMedium) {
      flex-basis: 576px;
      min-height: auto;
      height: auto;
    }
  }
}


.referralCodeButtonContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  width: 100%; /* Make width responsive */
  justify-content: center; /* Center the elements */
}


.referralButton, .referralCodeCopyButton {
  display: flex;
  width: fit-content;
  max-width: 700px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  background: #000;
  margin: auto;

  @media (--viewportSmall) {
  width: 100%;
    
  }
}

.referralCodeInput{
  background-color: #fff;
  padding-left: 1rem;
}

.codeHeading{
  font-size: 20px;
  margin-bottom: 2rem;
}

.close{
  position: absolute !important;
}

.error{
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}