@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
  background-color: #FCFBFB;
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  padding: 0;
}

.hero {
  padding: 48px 0 52px;

  height: 50vh;

  position: relative;
  left: 5%;
  right: 5%;
  width: 90%;

  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    padding: 46px 0 48px;
  }
}

.sections {
  padding: 0 24px;
  margin: 14px 0;

  @media (--viewportMedium) {
    padding: 0 32px;
  }

  @media (--viewportXLarge) {
    padding: 0 48px;
  }
}

.buttons{
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;

  @media (--viewportMedium) {
    max-width: 100%;
    flex-basis: 100%;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.aside {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;

  @media (--viewportMedium) {
    max-width: 25%;
    flex-basis: 25%;
  }

  @media (--viewportLarge) {
    max-width: 20%;
    flex-basis: 20%;
  }
}

.main {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;

  @media (--viewportMedium) {
    max-width: 75%;
    flex-basis: 75%;
  }

  @media (--viewportLarge) {
    max-width: 80%;
    flex-basis: 80%;
  }
}

.sectionContent {
}

.label{
  @apply --marketplacePrincipalLabel;
  text-align: center;
}
.label1{
  text-align: center;
  font-size:medium;
  color: var(--marketplaceColor);
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}
