@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ProfileSettingsForm_avatarSize: 115px;
  --ProfileSettingsForm_avatarSizeDesktop: 130px;
}

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.imagesContainer {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 65px;
  }
}

.sectionContainer {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0 50px;
    margin-bottom: 56px;
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightNormal);
  font-size: 24px;
  line-height: 26px;


  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
.contactDetailsSection {
  margin-top: 16px;
  margin-bottom: 46px;
  padding-top: 6px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
    padding-top: 0;
  }
}
.phone {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.actionsContainer {
  padding-bottom: 70px;
  @media (--viewportMedium) {
    padding: 0 50px 75px;
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  
}

.label {
  
}


.avatarWrapper {
  position: relative;
  top: -90px;
  margin: 0 auto -90px;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarPlaceholder,
.avatarContainer {

  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  font-size: 14px;
  font-size: 16px;

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;

  border: solid 3px white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}

.changeAvatar {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightBold);
  color: white;

  /* Positioning: under */
  position: absolute;
  bottom: 13px;
  right: auto;
  /* Dimensions */
  padding: 5px 6px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColor);
  border-radius: 10px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    transition: var(--transitionStyleButton);
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.rowContainer {
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.field {
  width: 100%;
}

.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.address {
  width: calc(50% - 9px);
}

.addressExtNumber {
  width: calc(25% - 9px);
}

.addressIntNumber {
  width: calc(25% - 9px);
}

.addressNeighborhood {
  width: calc(70% - 9px);
}

.addressZip{
  width: calc(30% - 9px);
}

.addressState {
  width: calc(50% - 9px);
}

.addressCity {
  width: calc(50% - 9px);
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;

  @media (--viewportMedium) {
    max-width: 260px;
  }
}

.uploadCoverWrapper {
  position: relative;
}

.coverContainer {
  cursor: pointer;
}

.uploadingCoverImage {
  /* Dimensions */
  height: 170px;;

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    height: 200px;
  }
}

.coverAspectRatio {
  padding-bottom: 170px;
  @media (--viewportMedium) {
    padding-bottom: 200px;
  }
}

.coverImageRoot {
  border-radius: 0;
}


.coverPlaceholder, .coverUploaded {
  background-color: var(--matterColorNegative); /* Loading BG color */
  background-size: cover;
  background-position: center center;
  height: 170px;
  @media (--viewportMedium) {
    height: 200px;
  }
}

.changeCover {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightBold);
  color: white;

  /* Positioning*/
  position: absolute;
  top: 20px;
  right: 15px;
  /* Dimensions */
  padding: 5px 6px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColor);
  border-radius: 10px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    transition: var(--transitionStyleButton);
  }
}

.uploadCoverInput {
  display: none;
}

.coverError {
  position: absolute;
  top: 10px;
  left: 15px;
  background-color: var(--matterColorNegative);
}
.error{
  color: red; /* Set the text color to red */
  font-size: 14px; /* Set the font size */
  font-weight: bold; /* Set the font weight to bold for emphasis */
  margin-top: 5px; /* Add some space between the element above and the error text */
  text-align: center;
}