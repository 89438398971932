@import '../../marketplace.css';


.referralCodeCopyButton{
    padding: 20px;
    align-items: center;
    gap: 10px;
    background: #000;
    border-radius: 5px;
}


.referralCodeInput{
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 10px;
    background: var(--neutral-white, #FFF) !important;
    border: none !important;

    color: var(--poshmark-com-invite-friends-390-x-693-default-tundora, #4A4A4A);

    /* poshmark_com_invite-friends_390x693_default/Roboto/SemiBold 17.5 upper */
    font-family: Roboto;
    font-size: 17.498px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* text-transform: uppercase; */
}

.referralCodeContainer{
    display: flex;
    flex: 2 1 auto;
    flex-direction: row;
}


.validationErrorMessage{
    @apply --marketplaceModalErrorStyles;
    text-align: center;
}

.referralButton{
    @apply --marketplaceH4FontStyles;
    
}

.referralMainContainer{
    @media (--viewportMedium) {
        margin: 0 48px 10px;
      }
}