@import '../../marketplace.css';

.content {
  width: 100%;

  @media (--viewportMedium) {
    max-width: 60vw;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  font-size: 36px;
  font-weight: normal;
  line-height: 38px;
  color: #000;

  margin: 0;
  hyphens: auto;
}

.formWrapper {
  margin-top: 32px;
  @media (--viewportMedium) {
    padding: 1px 50px 50px;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
  }
}
