@import '../../marketplace.css';

.root {
	background-color: white;
	border-radius: 4px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
	padding: 18px;
}

.title {
	margin: 0 0 17px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	color: #4a4a4a;
}

.brandBox {
	border: solid 1px #E9E9E9;
	padding-top: 100%;
	position: relative;
	background-repeat: no-repeat;
	background-size: 75%;
	background-position: center center;
}