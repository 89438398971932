@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.containerFormRegister{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr ;
  column-gap: 15px;
  row-gap: 16px;
  @media (--viewportMedium) {
    margin-top: 1px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 12px;
  }
}

/*-------*/
.firstNameRoot {
  width: calc(34% - 9px);
  order:1;
  grid-column: span 2;

}

.lastNameRoot {
  width: calc(66% - 9px);
  order:2;
}

.referralCodeContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1px;

  @media (--viewportMedium) {
    margin-top: 1px;
  }
}


.password {
  order:3;
  grid-column: span 2;
  @media (--viewportMedium) {
    margin-top: 0px;
    grid-column: span 1;
  }
}

.email{
order: 2;
grid-column: span 2;

  @media (--viewportMedium) {
    order: 2;

    grid-column: span 1;

  }
}


.referralCode {
  order: 6;
  margin-right: 0 !important;
  grid-column: span 2;
  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    grid-column: span 1;

  }
}

.usernameContainer{
  order: 4;
  grid-column: span 2;
  @media (--viewportMedium) {
    order: 5;

  }
}


/*-------*/


.name {
  display: flex;
  justify-content: space-between;
  margin-top: 1px;

  @media (--viewportMedium) {
    margin-top: 1px;
  }
}


.fieldRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.age {
  @apply --marketplaceModalPasswordMargins;
  width: 100%;
  @media (--viewportMedium) {
    margin-top: 12px;
    width: calc(50% - 9px);
  }
}

.username {
  width: 100%;
  @media (--viewportMedium) {
    width: calc(70% - 9px);
    margin-top: 12px;
  }
}


.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  @media (--viewportMedium) {
    margin-top: 50px;
  }
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  line-height: 1.2;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.phoneDisclaimer{
  /* @apply --marketplaceModalHelperLink; */
  font-size: small;
  font-weight: lighter;
  color:gray;
}


.linkReferredCode{
  border: hidden;
  color: #FD7C67;
  opacity: 0.75;

font-size: 15px;
font-weight: 600;
line-height: 18px;
letter-spacing: -0.10000000149011612px;
text-align: left;
width: 100%;
margin-right: 15px;
 height: 100%;
 display: flex;
 align-items: center;
 margin-top: 0px;
}

.linkReferredCode:hover{
  opacity: 1;
}


.referralCodeContainer{
  display: flex;
  flex-direction: column-reverse;
  order: 6;
}

.errorMessage{
  color: var(--failColor);
  order: 8;
  font-weight: 500;
  font-size: 16px;
  grid-column: span 2
}

.invisible{
  order: 8;
  visibility: hidden;
  font-weight: 500;
  font-size: 16px;
  grid-column: span 2
}