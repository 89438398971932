@import '../../marketplace.css';

/* Content is visible as modal layer */
.isOpen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo semi-transparente */


  /* scrollLayer is the .root for the Modal */

  & .container {
    @apply --marketplaceModalBaseStyles;


    @media (--viewportMedium) {
      flex-basis: 576px;
      min-height: auto;
      height: auto;
    }
  }
}

.scrollLayer {
  height: 100%;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
}


.content {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}


.close {
  @apply --marketplaceModalCloseStyles;
  position: absolute !important;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

.closeLight {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorLight);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}
