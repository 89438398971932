
.containerSectionProtection{
   background:  #D9D9D95E;
   padding-left: 14px;
   padding-top: 10px;
   padding-bottom: 7px;
   padding-right: 10px;
   border: 0.5px solid #D9D9D95E;
   font-size: 13px;
   @media (--viewportSmall) {
    width: 600px;
    margin-bottom: 30px;
  }

}

.containerInformation{
display: grid;
flex-direction: row;
grid-template-columns: 30px auto ;
gap: 8px;
align-items: center;
@media (--viewportSmall) {
    grid-template-columns: 30px auto;
  }

}

.safePurchaseText{
    color: #4A4A4A;
    margin: 0px;
    font-size: 14px !important;
    
}

.moreInformationText{
    color:#979797;
    font-size: 12px;
}

.safePurchaseText span {
    color: #812039;
    font-weight: 600;
}
.authProtectionText{
    color : #20923D;
    margin: 0px;
    font-size: 14px !important;
     
}
.authProtectionText span{
    font-weight: 600;
}

.containerSectionDetailUser{
    margin-top: 25px;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 7px;
    padding-right: 10px;
    border: 0.5px solid #D9D9D95E;
    font-size: 13px;
    display: grid;
    grid-template-columns: 164px  1fr 1fr ;
    @media (--viewportSmall) {
        width: 400px;
       
      }

}

.containerAvatar{
    display: flex;
    gap: 11px;
    
    align-items: center;
}

.containerAvatar  label{
    color: #812039;
    line-height: 16px;
    font-weight: 500;
}


.containerAvatar  > span{
    line-height: 11px;
    font-weight: 500;
    font-size: 11px;
    color: #4A4A4A;
}

.containerText{
    text-align: center;
    color:#4A4A4A;
}

.containerText_span {
    font-size: 12px !important;
    font-weight: 500 !important;
}
.containerText_label{
    font-size: 12px !important;
    font-weight: 500 !important;
}