@import '../../marketplace.css';

.container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Make width responsive */
    max-width: 700px; /* Max width for larger screens */
    padding: 50px 14px 50px 14px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background: #F5F2EE;
    margin: auto; /* Center the container */
}

.referralButton, .referralCodeCopyButton {

    @apply --marketplaceButtonStylesPrimary;

    /* Clear padding that is set for link elements looking like buttons */
    padding: 0;

    font-family: CircularStd, Arial, sans-serif;
    display: flex;
    width: 100%; /* Make width responsive */
    max-width: 700px; /* Max width for larger screens */
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #000;
    margin: auto; /* Center the buttons */
}

.referralCodeSocialMediaIconsContainer {
    display: flex;
    width: 85%; /* Make width responsive */
    max-width: 700px; /* Max width for larger screens */
    padding: 10px 14px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background: #F5F2EE;
    justify-content: space-around; /* Center the icons */
    margin-top: 7px;
}

.socialMediaButton {
    font-family: CircularStd, Arial, sans-serif;
    display: flex;
    width: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 5px; /* Adjust margin for smaller screens */
    border: none;
}

.referralCodeInput {
    font-family: CircularStd, Arial, sans-serif;
    display: flex;
    width: 100%; /* Make width responsive */
    max-width: 400px; /* Max width for larger screens */
    padding: 20px;
    align-items: center;
    gap: 10px;
    background: var(--neutral-white, #FFF) !important;
    border: none !important;
    color: var(--poshmark-com-invite-friends-390-x-693-default-tundora, #4A4A4A);
    font-family: CircularStd;
    font-size: 17.498px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.referralCodeButtonContainer {
    display: flex;
    flex-direction: row;
    width: 100%; /* Make width responsive */
    justify-content: center; /* Center the elements */
}

.socialMediaIconText {
    color: #767676;
    text-align: center;
    font-family: CircularStd;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.3px;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
    .container {
        padding: 48px 7px 5px 7px; /* Adjust padding for smaller screens */
    }

    .referralCodeInput, .referralButton, .referralCodeCopyButton {
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .socialMediaButton {
        width: 50px; /* Adjust width for smaller screens */
    }
}
