@import '../../marketplace.css';
@import '../../marketplaceFonts.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
  background-color: #FCFBFB;
}

.label{
    @apply --marketplacePrincipalLabel;
    text-align: center;
    font-family: 'CircularStd', sans-serif !important;
    font-weight: bold;

  }
.label1{
    text-align: center;
    font-size:medium;
    color: var(--marketplaceColor);
}
.title{
  @apply --marketplacePrincipalLabel;
  text-align: center;
  font-size:xx-large;
  color: var(--marketplaceColor);
}

  .bottomWrapper {
    @apply --marketplaceModalBottomWrapper;
    @media (--viewportMedium) {
      margin-top: 50px;
    }
  }
  
  .bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;
    line-height: 1.2;
  }
  
  .termsText {
    @apply --marketplaceModalHelperText;
  }

  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0;
    @media (max-width: 768px) {
      width: 80%;
    }
  }

  .character{
    border-radius: 5px;
    border: 1px solid;
    font-size: 25px;
    text-align: center;
    width: 50px;
    height: 60px;
    margin: 5px;
    font-weight: 300;
    @media (max-width: 768px) {
      height: 40px;
      font-size: 15px;
      width: 10vw;
    }
  }

  .character--inactive{

  }

  .character--selected{

  }

  .buttonResend{
    font-size:medium;
    color: var(--marketplaceColor);
    border: none; 
    outline: none;  
    text-decoration: underline;
  }

  .error{
    color: red; /* Set the text color to red */
    font-size: 14px; /* Set the font size */
    font-weight: bold; /* Set the font weight to bold for emphasis */
    margin-top: 5px; /* Add some space between the element above and the error text */
    text-align: center;
  }