
.menu {
    max-width: 100%;
    height: auto;
  }
  
  .menuLabel {
    text-align: left;
    font-weight: 500;
    padding: 0;
    border-radius: 4px;
    border: 1px solid var(--matterColorNegative);
    transition: all 0.2s ease-out;
    z-index: calc(var(--zIndexPopup) + 1);
    position: relative;
  
    &:hover {
      background-color: var(--matterColorLight);
      border: 1px solid var(--matterColorAnti);
    }
  
    &:focus {
      outline: none;
      background-color: var(--matterColorLight);
      border: 1px solid var(--matterColorNegative);
      text-decoration: none;
      border-radius: 4px 4px 0px 0px;
    }
  }
  
  .menuLabelWrapper,
  .menuLabelWrapperExpired {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 12px 24px 18px 16px;
  }
  
  .menuLabelWrapperExpired {
    border: 1px solid var(--failColor);
  }
  
  .menuContent {
    position: absolute;
    z-index: var(--zIndexPopup);
  
    background-color: var(--matterColorLight);
    box-shadow: var(--boxShadowFilterButton);
    border-radius: 4px;
    border: 1px solid var(--matterColorNegative);
  
    transition: all 0.15s ease-out;
  
    /* When the dropdown is open, we need to hack the box-shadow's position
      to fit the whole dropdown menu component with padding-top
  
      MenuLabel height 56px + original padding-top 7px results to 63px
    */
    padding: 63px 0 15px 0;
    top: 0px;
  }
  
  .menuItem {
    color: var(--matterColor);
    &:hover {
      background-color: #f2f2f2;
    }
  }
  
  .menuText,
  .menuTextReplaceCard {
    @apply --marketplaceMessageFontStyles;
    padding: 12px 8px;
    color: var(--matterColor);
    text-align: left;
    font-weight: 500;
    width: 80%;
  
    &:hover {
      text-decoration: none;
    }
  
    &:focus {
      outline: 0;
      background-color: #f2f2f2;
    }
  }
  
  .menuTextReplaceCard svg {
    position: relative;
    top: -2px;
    width: 22px;
    margin: 0 10px 0 4px;
  }
  
  .menuDivider {
    @apply --marketplaceH5FontStyles;
    color: var(--matterColorAnti);
    padding: 14px 24px 2px 24px;
    margin: 0;
  }

  .MsiSelectContainer{
    width: 90%;
  }