@import "../../marketplace.css";

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  };
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.imgHero {
  width: 100%;
  height: auto;
  position: absolute;
  display: flex;
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
  @media (--viewportMedium) {
  }
}

.desktopOnly {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.mobileOnly {
  display: block;
  -webkit-user-drag: none;
  user-drag: none;
  @media (--viewportMedium) {
    display: none;
  }
}


.root {
  border: 0;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  align-items: center;
  display: flex;

  /* Text positioning is done with paddings */
}

.contentContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 5rem;
  @media (--viewportMedium) {
    padding-left: 3rem;
    padding-top: 1rem;
    padding-right: 0rem;
  }
}

.heroMainTitle {
  font-size: 38px;
  font-weight: normal;
  color: var(--matterColorLight);

  margin: 0 0 16px;

  @apply --SectionHero_animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    font-size: 42px;
  }
}

.heroSubTitle {
  @apply --marketplaceBodyFontStyles;

  font-size: 16px;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  @apply --SectionHero_animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    font-size: 18px;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 57px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  @apply --SectionHero_animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.linkToHowItWorks {
  @apply --marketplaceLinkStyles;
  @apply --SectionHero_animation;

  color: white;

  display: block;
  margin-top: 15px;
  font-size: 20px;
}
