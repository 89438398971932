@import '../../marketplace.css';

.root {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.threeToTwoWrapper {
  display: block;
  width: 100%;
  position: relative;
  transition: ease-in-out 0.1s;
}

.aspectWrapper {
  padding-bottom: calc(100% * (1 / 1));
}
.modalCropper{
  height: 50vh;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.canvas{
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}


.hover{
  display: none;
  @media (--viewportSmall) {
    display:block;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
  }
}

.image{
  width: 50%;
  opacity: 0.75;
}
.imageSrc{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 4px 4px 0 0;
  margin-top: 50%;
  transform: translatey(-50%);
}

.threeToTwoWrapper:hover .canvas{
  opacity: 0.3;
}

.threeToTwoWrapper:hover .hover{
  opacity:1;
}

.cropContainer{
  position: relative;
  width: 100%;
  height: 200px;
  background: gray;
  height: 400px;
}

.cropButton{
  cursor:'pointer';
  z-index: 100;
  flex-shrink: 0;
  background-color: var(--marketplaceColor);
  margin-top: 20px;
  color: white;
  border: 1px solid gray;
}

.controls{
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.sliderContainer{
  display: flex;
  flex: 1;
  align-items: center;
}

.sliderLabel{
  margin-right: 15px;
  color: gray;
}

.slider {
  padding: 22px 0px;
  margin-left: 32px;
}

.modalBody{
  background: white;
  border: none;
  display: flex;
  flex-direction: column;
}

.modalFlexContainer{
  display: flex;
  flex-direction: column;
}
.closeButton{
  color: gray;
  width: min-content;
  align-self: flex-end;
  margin-right: 0;
  border: none;
}

.showCropperButton{
  position: absolute;
  width: 33px;
  height: 33px;
  float: left;
  color: var(--marketplaceColor);
  min-height: 0;
  border-radius: 30px;
}

.showCropperButton {
  position: relative;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  cursor: pointer;
  border-radius: 30px;
  background: var(--marketplaceColor);

  float: left;

  @media (--viewportMedium) {
    width: 33px;
    height: 33px;
  }

  & svg {
    position: relative;
    fill: none;
    color: white;
    bottom: 4px;
    width: 20px;

    @media (--viewportMedium) {
      bottom: 1px;
      width: auto;
    }
  }
}