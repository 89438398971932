@import '../../../marketplace.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--successColor);
  }

  /* Hightlight the text on checked, hover and focus */
  &:checked + label .text {
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    &:hover + label .text {
      color: var(--marketplaceColor);
    }
  }
  &:checked + label .text:after {
    content: 'check';
    font-family: 'Material Icons';
    position: absolute;
    right: -15px;
    top: 0;
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
}


.checked {
  display: none;
  fill: var(--marketplaceColor);
}
.checkedSuccess {
  display: none;
  fill: var(--successColor);
}

.boxSuccess,
.box {
  stroke: var(--matterColorAnti);
}

.text {
  position: relative;
}
.textRoot {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
